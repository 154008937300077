import './Footer.css';
import React from 'react';
import { useState , useEffect} from "react";
import BackendApi from '../api/BackendApi';
import BackendInfraApi from '../api/BackendInfraApi';
import FooterLinks from './FooterLinks';

function FooterPublic() {

  const [errorMsg, setErrorMsg] = useState("");

  const [backendInfraStatus, setBackendInfraStatus] = useState({});

  const [backendApiRunning, setBackendApiRunning] = useState(true);

  useEffect(() => {

    getApiServicesStatus()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApiServicesStatus = () => {
    
    BackendInfraApi.getServicesStatus().then((response) => {
      console.log("backend infra response ", response.data);

      let statuses = response?.data

      setBackendInfraStatus(statuses)

      if(statuses){
        let api_running_count = statuses?.ecs_api_running_count || 0
        let graphql_running_count = statuses?.ecs_graphql_running_count || 0
        let db_status = statuses?.db_status

        setBackendApiRunning(api_running_count > 0 && graphql_running_count > 0 && db_status==="available")

      }


    }).catch((error) => {
      console.error("backend infra error response: ", error);
      if(error.response.data){
        setErrorMsg(error.response.data.message)
      }else{
        setErrorMsg(error.message+". Api server is down")
      }
    });

  }

  useEffect(() => {
    pingApiServer()

    // Set an interval to call it every 5 minutes
    const intervalId = setInterval(() => {
      pingApiServer()

      getApiServicesStatus()
    }, 300000); // 300000 ms = 5 minutes

    // Cleanup: clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pingApiServer = () => {
    
    BackendApi.pingApiServer().then((response) => {
      console.log("api server ping response ", response.data);
      setErrorMsg("")
    }).catch((error) => {
      console.error("api server ping error response: ", error);
      if(error.response.data){
        setErrorMsg(error.response.data.message)
      }else{
        setErrorMsg(error.message+". Api server is down")
      }
      
    });

  }
  const startBackendApiServices = () => {
    BackendInfraApi.startApiServices().then((response) => {
      console.log("backend infra start response ", response.data);
  
      setErrorMsg("")

      getApiServicesStatus()
    }).catch((error) => {
      console.error("backend infra start error response: ", error);
      if(error.response.data){
        setErrorMsg(error.response.data.message)
      }else{
        setErrorMsg(error.message+". Api server is down")
      }
      
    });
  }

  const stopBackendApiServices = () => {
    BackendInfraApi.stopApiServices().then((response) => {
      console.log("backend infra stop response ", response.data);
  
      setErrorMsg("")

      getApiServicesStatus()
    }).catch((error) => {
      console.error("backend infra start error response: ", error);
      if(error.response.data){
        setErrorMsg(error.response.data.message)
      }else{
        setErrorMsg(error.message+". Api server is down")
      }
      
    });
  }

  

  return (
      <div className="row">
        <div className="col-12">
          <div className='row mt-5'>
            <div className="col-12 col-md-4">
            {
              errorMsg && 
              <div className="alert alert-danger">
                {errorMsg}
              </div>
            }
            </div>
          </div>
          <div className='row mt-1'>
            <div className="col-12 col-sm-12">
            <FooterLinks />
            </div>
          </div>
          <div className='row mt-1'>
            <div className="col-12 col-md-12">
            {
              <div className='row'>
                <div className="col-12 col-md-12">

                <div className='row'>
                  <div className="col-6 col-md-3">
                    DB Server Status: {backendInfraStatus.db_status}
                  </div>
                  <div className="col-6 col-md-3">
                    Api Server running count: {backendInfraStatus.ecs_api_running_count}
                  </div>
                  <div className="col-6 col-md-3">
                    Api Server desired count: {backendInfraStatus.ecs_api_desired_count}
                  </div>
                  <div className="col-6 col-md-3">
                    Api Server pending count: {backendInfraStatus.ecs_api_pending_count}
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className="col-12 col-md-12">
                  {
                    !backendApiRunning && 
                    <button onClick={()=>startBackendApiServices()} type="button" className="btn btn-outline-primary">Start Api Services</button>
                  }

                  {
                    backendApiRunning && 
                    <button onClick={()=>stopBackendApiServices()} type="button" className="btn btn-outline-primary">Stop Api Services</button>
                  }
                  
                  </div>
                </div>

                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
  );
}

export default FooterPublic;
