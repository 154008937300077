import React from 'react';
import {
  useNavigate
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

function MainNavbar() {

  const navigate = useNavigate();

  return (
    <Nav defaultActiveKey="/chart-patterns/double-top" className="flex-column">

      <Nav.Link
        className="border border-bottom-0"
        onClick={()=>navigate("/")}
      >
        Home
      </Nav.Link>
      <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/trades")}
      >
        Trades
      </Nav.Link>
      {/* <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/off-hour-moves")}
      >
        Off Hour Moves
      </Nav.Link> */}
      {/* <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/gap-up-down")}
      >
        Gap Up/Down
      </Nav.Link> */}
      <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/earning-report")}
      >
        Earning Report
      </Nav.Link>
      <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/all-stocks")}
      >
        All Stocks
      </Nav.Link>
      <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/all-cryptos")}
      >
        All Crypto
      </Nav.Link>
      <Nav.Link 
        className="border"
        onClick={()=>navigate("/journal")}
      >
        Journal
      </Nav.Link>

      
    </Nav>
  );
}

export default MainNavbar;
