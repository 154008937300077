import React from 'react';
import { useEffect, useState } from "react";
import ZoomImageModal from "../../components/ZoomImageModal";
import YouTube from 'react-youtube';

const images = [
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-top/double_top_1.png",
    alt: "double_top_1",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-top/double_top_2.png",
    alt: "double_top_2",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-top/double_top_3.png",
    alt: "double_top_3",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-top/double_top_4.png",
    alt: "double_top_4",
    explanation:""
  }
]

const videos = [
  {
    youTubeId: "DF9uHpbb5Z8",
    title: "How to Trade the Double Top Chart Pattern Like a PRO"
  },
  {
    youTubeId: "tqB_NPRz3GQ",
    title: "The Only Double Top Trading Strategy You Will Ever Need"
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/terms/d/doubletop.asp"
  },
  {
    title: "StockChart School",
    url: "https://school.stockcharts.com/doku.php?id=chart_analysis:chart_patterns:double_top_reversal"
  },
  {
    title: "Daily Price Action",
    url: "https://dailypriceaction.com/forex-beginners/double-top-pattern/"
  },
  {
    title: "Tradingview",
    url: "https://www.tradingview.com/ideas/wedgepattern/"
  }
]

function WedgeChartPatternPage() {

  console.log("WedgeChartPatternPage")

  const [zoomImage, setZoomImage] = useState({show: false, imageUrl:"",imageAlt:""})

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomInImage = (image) => {
    console.log("zoomInImage, ", image)
    setZoomImage({show: true, imageUrl: image.url, imageAlt: image.alt})
  }

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h4>Wedge Chart Pattern</h4>
            </div>
          </div>


          <div className='row'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
              <h5>Descriptions</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                The Double Top is a bearish reversal chart pattern that appears at the end of an uptrend. This pattern is characterized by two consecutive peaks that are roughly equal, with a moderate trough in-between, resembling the letter "M". The Double Top signals a turning point and a good possibility that a reversal from an uptrend to a downtrend will occur.
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-12'>
              <h5>Key Features</h5>
              </div>
            </div>

            <ol>
              <li><b>First Top</b>: The first peak is the highest point of the existing trend.</li>
              <li><b>Trough</b>: After the first peak, there is a decline, which is the trough. This usually retraces from 10-20% before the second peak starts forming.</li>
              <li><b>Second Top</b>: The second peak is formed when the price tries to go up again but fails to go above the first peak.</li>
              <li><b>Confirmation</b>: The pattern is confirmed when the price breaks below the lowest point of the trough.</li>
            </ol>

            <div className='row'>
              <div className='col-12'>
                <h5>Note *</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              Investors typically enter a short position when the price falls below the support level of the trough.
              </div>
            </div>
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

              <div className='row'>
                <div className='col-12'>
                  <h5>Images</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <small>(click on image to zoom in)</small>
                </div>
              </div>

              <div className='row'>
                {
                  images.map((image) => (
                    <div className='col-12 col-sm-3 cursor-selection' key={image.alt} onClick={()=>zoomInImage(image)}>
                      <img src={image.url} className="img-fluid" alt={image.alt}></img>
                      <div></div>
                      {image.explanation}
                    </div>
                    
                  ))
                }
              </div>    
              <div className='row'>
                <div className='col-12'>
                  <ZoomImageModal 
                    showModal={zoomImage.show} 
                    imageUrl={zoomImage.imageUrl} 
                    imageAlt={zoomImage.imageAlt} 
                    closeModal={()=>(
                      setZoomImage({show: false})
                    )}
                    />
                </div>
              </div>   
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default WedgeChartPatternPage;
