import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TradeApi from '../api/TradeApi';
import { TradeAssetType } from './enum/OrderEnums';
import TradeGraphQL from '../graphql/TradeGraphQL';

function TradeHistory() {

  const [searchParams,] = useSearchParams();

  const navigate = useNavigate();

  const [trade, setTrade] = useState({id:0, notes:[]});

  const [note, setNote] = useState({content:""});

  const [errorMsg, setErrorMsg] = useState("");

  const [rating, setRating] = useState(0);

  const [disabledAddNoteBtn, setDisabledAddNoteBtn] = useState(true);

  // Get a specific query parameter
  const uuid = searchParams.get('uuid');

  useEffect(() => {
    loadTrade()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadTrade = () => {

    TradeGraphQL.getTradeByUuid(uuid).then((response) => {
      console.log("trade details response ", uuid, response);  
      let tradeDetail = response?.data?.trades?.[0] || {}
      setTrade(tradeDetail)
      setDisabledAddNoteBtn(false)
      setRating(tradeDetail.rating)

    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.response.data.message)
    });
    
    // TradeApi.getByUuid(uuid).then((response) => {
    //   console.log("trade details response ",trade.ticker, response);  
    //   let tradeDetail = response.data
    //   setTrade(tradeDetail)
    //   setDisabledAddNoteBtn(false)

    //   setRating(response.data.rating)

    // }).catch((error) => {
    //     console.error("Error: ", error);
    //     setErrorMsg(error.response.data.message)
    // });
  }

  const handleNoteChange = (e) => {
    setNote({
      ...note,
      [e.target.name]: e.target.value,
    });
  };

  const addNote = () => {
    console.log("note, ", JSON.stringify(note))

    TradeApi.addNote(uuid, note).then((response) => {
      console.log("response: ", response);

      setTrade(response.data)
      
    }).catch((error) => {
      console.error("Error: ", error);
    });

    setNote({content:""})
  }

  const submitRating = (star) => {
    
    TradeApi.submitRating(uuid, star).then((response) => {
      console.log("response: ", response);

      setTrade(response.data)

      setRating(response.data.rating)
      
    }).catch((error) => {
      console.error("Error: ", error);
    });

    setNote({content:""})
  }

  return (
    <>
      <div className='row border'>
        <div className="col-12 col-md-12">
          <div className='row'>
            <div className="col-12 col-md-12">
            {
              errorMsg && 
              <div className="alert alert-danger">
                {errorMsg}
              </div>
            }
            </div>
          </div>
          <div className='row'>
            <div className="col-12 col-md-12">
              <b>Trade History</b>
            </div>
          </div>
          <div className='row'>
            <div className="col-12 col-md-12">
              <div className='row border m-2 p-2'>
                <div className="col-12">
                  <div className='row'>
                    <div className="col-6 col-sm-1">
                      <button 
                        onClick={()=>navigate((trade.assetType===TradeAssetType.OPTIONS) ? '/option?ticker='+trade.ticker : '/stock?ticker='+trade.ticker)}
                        type="button" className="btn btn-link btn-sm">{trade.ticker}</button>
                    </div>
                    <div className="col-6 col-sm-1">
                      <b>Id</b>
                      <br/>
                      {trade.id}
                    </div>

                      <div className={trade.profit >= 0.0 ? 'col-6 col-sm-2 profit-positive' : 'col-6 col-sm-2 profit-negative'}>
                        <b>Profit</b>
                        <br/>
                        ${trade.profit}
                      </div>
                    <div className="col-6 col-sm-3">
                    </div>
                    
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6 col-md-3">
                      Purchase Total Price: ${trade.purchasedTotalPrice}
                    </div>
                    <div className="col-6 col-md-3">
                      Current Total Price:
                    </div>
                    <div className="col-6 col-md-3">
                      Share Purchased Price: ${trade.sharePurchasedPrice}
                    </div>
                    <div className="col-6 col-md-3">
                      Share Purchased Price: ${trade.sharePurchasedPrice}
                    </div>
                   
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6 col-md-3">
                      Stop Loss Limit Price: ${trade.stopLossLimitPrice}
                    </div>
                    <div className="col-6 col-md-3">
                      Stop Loss Price: ${trade.stopLossPrice}
                    </div>
                    <div className="col-6 col-md-3">
                      Direction: {trade.direction}
                    </div>
                    <div className="col-6 col-md-3">
                      Order Type: {trade.orderType}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    
                    <div className="col-6 col-md-3">
                      Asset Type: {trade.assetType}
                    </div>
                    <div className="col-6 col-md-3">
                      Number of Shares: {trade.numberOfShares}
                    </div>
                    <div className="col-6 col-md-3">
                      Adjust Stop Limits
                    </div>
                    <div className="col-6 col-md-3">
                      Updated At: {trade.updatedAt}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
          <label className="form-label">Rating</label>
          <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`fa fa-star ${star <= rating ? "text-warning" : "text-muted"}`}
                onClick={() => submitRating(star)}
              ></span>
            ))}
          </div>
        </div>

          <div className='row'>
            <div className="col-12 col-md-12">
             <div className='row'>
                <div className="col-12 col-md-12">
                  <b>Notes</b>
                </div>
              </div>
              <div className='row'>
                        
                <div className="col-12 col-md-12">
                  <div className="mb-3">
                    <textarea 
                    value={note.content}
                    onChange={handleNoteChange}
                    name='content'
                    className="form-control" 
                    rows="3"></textarea>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-12 col-md-12">
                  <button
                  disabled={disabledAddNoteBtn}
                  onClick={()=>addNote()}
                  type="button" className="btn btn-outline-primary">Add Note</button>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-md-12">
                  {
                    trade.notes.length > 0 && 
                    trade.notes.map(note => (
                      <div key={note.id} className="card mb-2">
                        <div className="card-body">
                          <div className='row'>
                            <div className="col-12 col-md-12 card-text">
                            {note.content}
                            </div>
                          </div>
                          <div className='row mt-3'>
                            <div className="col-12 col-md-12">
                            {note.createdAt} by {note.user.firstName} {note.user.lastName}
                            </div>
                          </div>
                        </div>
                      </div>

                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default TradeHistory;
