import React from 'react';
import { useState , useEffect} from "react";
import {
  useNavigate
} from "react-router-dom";
import SecurityGraphQL from '../graphql/SecurityGraphQL';
import { TradeAssetType } from '../components/enum/OrderEnums';

function AllCryptoPage() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [allCryptos, setAllCryptos] = useState([]);


  useEffect(() => {

    console.log("AllCryptoPage")

    SecurityGraphQL.getAllSecuritiesByType(TradeAssetType.CRYPTO).then((response) => {
      console.log("crypto details graphql response: ", response.data);  
      setAllCryptos(response?.data?.securities || [])
    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  useEffect(() => {
    
  }, []);



  return (
    <>
      <div className='row'>
        <div className='col-12'>

          <div className='row mb-3'>
            <div className="col-12 col-sm-12">
              <button 
              onClick={()=>navigate("/security-create")}
              type="button"
              className="btn btn-outline-primary">Add</button>
            </div>
          </div>

          <div className='row mb-3'>
            <div className="col-12 col-sm-12">
              {
                allCryptos && allCryptos.length <= 0 &&
                <div className="alert alert-light">
                   No Cryptos. Backend issue!
                </div>
              }
            </div>
          </div>
          
          <div className='row'>
            <div className="col-12 col-sm-12">
              

            <div className='row'>
            {
            
            allCryptos.map((crypto)=>(
                
                  <div className="col-12 col-sm-3 mb-3" key={crypto.id}>


                    <div className='row'>
                      <div className="col-12 col-sm-12">
                      
                          <div className="card">
                            <div className="card-body">
                              <div className='row'>
                                <div className="col-12 col-sm-6">
                                  <h5 className="card-title">{crypto.name}</h5>
                                </div>
                                <div className="col-12 col-sm-6 text-end">
                                  <small><button className="btn btn-link" onClick={()=>navigate("/security-update?ticker="+crypto.ticker)}>edit</button></small>
                                </div>
                              </div>
                              <h6 className="card-subtitle mb-2 text-body-secondary">
                                <button 
                                  onClick={()=>navigate("/stock?ticker="+crypto.ticker)}
                                  type="button" 
                                  className="btn btn-link m-0 p-0">
                                ({crypto.ticker})
                                </button>
                              </h6>
                              
                              <div className='row'>
                                  <div className="col-12 col-sm-12">

                                    <div className='row'>
                                      <div className="col-12 col-sm-12">
                                        {crypto.reportDate}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            </div>
                          </div>

                        
                      </div>
                    </div>
                 
                  </div>
              ))
            }
             </div>


            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                  
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
    </>
  );
}

export default AllCryptoPage;
