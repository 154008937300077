import './Footer.css';
import React from 'react';
import FooterLinks from './FooterLinks';

function Footer() {

  return (
    <div className="row">
      <div className="col-12">
      
        <div className='row mt-1'>
          <div className="col-12 col-sm-12">
          <FooterLinks />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
