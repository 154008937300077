import React from 'react';
import { useEffect, useState } from "react";
import ZoomImageModal from "../../components/ZoomImageModal";
import YouTube from 'react-youtube';

const images = [
]

const videos = [
  {
    youTubeId: "kmrKLw9btqQ",
    title: ""
  },
  {
    youTubeId: "cjoEGsB7ph4",
    title: ""
  },
  {
    youTubeId: "8hM18AHcUCs",
    title: ""
  }
]

const resources = [
  {
    title: "Center Point",
    url: "https://centerpointsecurities.com/vwap-indicator/"
  },
  {
    title: "StockChart School",
    url: "https://school.stockcharts.com/doku.php?id=technical_indicators:vwap_intraday"
  },
  {
    title: "Daily Price Action",
    url: "https://dailypriceaction.com/forex-beginners/double-top-pattern/"
  },
  {
    title: "Charles Schwab",
    url: "https://www.schwab.com/learn/story/how-to-use-volume-weighted-indicators-trading"
  }
]

function RsiPage() {

  console.log("RsiPage")

  const [zoomImage, setZoomImage] = useState({show: false, imageUrl:"",imageAlt:""})

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomInImage = (image) => {
    console.log("zoomInImage, ", image)
    setZoomImage({show: true, imageUrl: image.url, imageAlt: image.alt})
  }

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>RSI Indicator</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            The Volume-weighted Average Price (VWAP) is a trading benchmark that combines the average price an asset has traded at throughout the day, 
            based on both volume and price. It is a measure of the average price a security has traded at throughout the day, 
            based on both volume and price levels. Here's a comprehensive breakdown of what you need to know about VWAP:
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>Understanding VWAP:</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Definition</b>: VWAP stands for Volume-weighted Average Price. It's a technical analysis tool that reflects the average price of an asset weighted by volume over a trading session​
                    </li>
                    <li>
                      <b>Importance</b>: It's used by traders and institutions to make informed decisions, as it helps to validate the prevailing market sentiment. Institutions use it to execute large trades with minimal market impact​
                    </li>
                    <li>
                      <b>Benchmarking</b>: VWAP can act as a target for traders, especially when executing large orders. If a trader can purchase below the VWAP, they achieve a better-than-average price
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>How VWAP Works:</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Real-Time Adjustment</b>: VWAP is continuously updated throughout the trading day, incorporating real-time changes in price and volume, which keeps it relevant for traders
                    </li>
                    <li>
                      <b>Trend Confirmation</b>: It is used to define trends. Stocks trading below the VWAP may be considered undervalued, and those above it, overvalued. This can inform decisions on when to enter or exit trades
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>Calculating VWAP</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Typical Price (TP)</b>: It starts with calculating the TP for the first completed bar or candle on your chart, which depends on the time frame you're using (e.g., one minute, five minutes, fifteen minutes)
                    </li>
                    <li>
                      <b>Calculation Steps</b>: To calculate VWAP, you:
                      <ul>
                        <li>Select your time frame.</li>
                        <li>Calculate the TP by adding the high, low, and closing prices and dividing by three.</li>
                        <li>Multiply the TP by the volume for that period (TPV)</li>
                        <li>Keep a running total of TPV and cumulative volume.</li>
                        <li>Divide the cumulative TPV by the cumulative volume</li>
                      </ul>

                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              In practice, traders can use the VWAP to make strategic decisions, such as whether to buy or sell an asset. 
              If an asset's price is below the VWAP, it might be a good time to buy as it indicates the price is lower than the average. Conversely, 
              if the price is above the VWAP, it might be a signal to sell. However, it's important to note that VWAP is just one of many tools traders use 
              and should not be the sole basis for any trading decision. Combining VWAP with other indicators and a clear understanding of market conditions 
              can lead to more informed and strategic trading decisions.
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

              <div className='row'>
                <div className='col-12'>
                  <h5>Images</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <small>(click on image to zoom in)</small>
                </div>
              </div>

              <div className='row'>
                {
                  images.map((image) => (
                    <div className='col-12 col-sm-3 cursor-selection' key={image.alt} onClick={()=>zoomInImage(image)}>
                      <img src={image.url} className="img-fluid" alt={image.alt}></img>
                      <div></div>
                      {image.explanation}
                    </div>
                    
                  ))
                }
              </div>    
              <div className='row'>
                <div className='col-12'>
                  <ZoomImageModal 
                    showModal={zoomImage.show} 
                    imageUrl={zoomImage.imageUrl} 
                    imageAlt={zoomImage.imageAlt} 
                    closeModal={()=>(
                      setZoomImage({show: false})
                    )}
                    />
                </div>
              </div>   
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default RsiPage;
