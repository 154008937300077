import './OffHourMove.css';
import React from 'react';
import { useState , useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import OffHourMoveGraphQL from '../graphql/OffHourMoveGraphQL';
import DateTimeUtil from '../utility/DateTimeUtil'

function OffHourMove() {

  const [offHourMoves, setOffHourMoves] = useState([{id:0}]);

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {

    loadTodayOffHourMoves()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTodayOffHourMoves = () => { 
    console.log("load today offHourMoves");
    OffHourMoveGraphQL.getTodayOffHourMoves(DateTimeUtil.getTodayDate()).then((response) => {
      console.log("today offHourMoves response: ", response.data);

      let offHourMoves = response.data?.off_hour_moves

      if(offHourMoves){
        setOffHourMoves(offHourMoves)
      }else{
        loadHistoricalBigMoves()
      }

      
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });

  }

  const loadHistoricalBigMoves = () => {
    console.log("load historical GapUpOrDown");

    OffHourMoveGraphQL.getHistoricalOffHourMoves(10).then((response) => {
      console.log("historical offHourMove response: ", response.data);

      let offHourMoves = response.data?.off_hour_moves || []

      setOffHourMoves(offHourMoves)

      
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h1>Off Hour Moves</h1>
          <div className="col-12 col-md-12">
            <div className="row">
              { offHourMoves.length > 0 &&
                offHourMoves.map((offHourMove)=>(
                  <div className="col-12 col-md-4 p-3" key={offHourMove.id}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 menuCardTitle">

                            <button 
                              onClick={()=>navigate('/stock?ticker='+offHourMove.ticker)}
                              type="button" className="btn btn-link btn-sm">{offHourMove.ticker}</button>

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Price Diff: {offHourMove.priceDiff >= 0.0 ? ('$'+offHourMove.priceDiff) : ('-$'+(offHourMove.priceDiff)*-1) }
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="col-12">
                            High/Low Diff: {offHourMove.highLowDiff >= 0.0 ? ('$'+offHourMove.highLowDiff) : ('-$'+(offHourMove.highLowDiff)*-1) }
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Start Price: ${offHourMove.startPrice}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Start Time: {offHourMove.startDateTime}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            End Price: ${offHourMove.endPrice}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            End Time: {offHourMove.endDateTime}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffHourMove;
