import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const WatchListApi = {

    getByTitle: (title) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/watchlists/get-by-title?title='+title, options);
    },
    updateProfile: (payload) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.put('/users', JSON.stringify(payload), options);
    },
}

export default WatchListApi;