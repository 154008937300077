
function IndicatorPage() {

  console.log("IndicatorPage")

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Technical Indicators</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            Chart patterns are important tools in technical analysis for understanding market sentiment and predicting future price movements. 
            They give traders insights into market psychology, helping them make educated decisions on entry and exit points. 
            Recognizing these patterns can increase the probability of successful trades.
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>How to Use Them</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Identification</b>: The first step is to identify a pattern forming in the chart.
                    </li>
                    <li>
                      <b>Confirmation</b>: Wait for the pattern to complete. In some cases, volume analysis or other indicators can confirm the pattern.
                    </li>
                    <li>
                      <b>Entry/Exit Strategy</b>: Based on the pattern, determine your entry and exit points.
                    </li>
                    <li>
                      <b>Risk Management</b>: Always consider stop-loss and take-profit levels to manage risk.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default IndicatorPage;
