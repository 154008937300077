
export const OrderType = {
    MARKET_ORDER : 'MARKET_ORDER',
    LIMIT_ORDER : 'LIMIT_ORDER'
}

export const TradeDirection = {
    LONG : 'LONG',
    SHORT : 'SHORT'
}

export const TradeStatus = {
    PENDING_LIMIT_ORDER : 'PENDING_LIMIT_ORDER',
    PURCHASED : 'PURCHASED',
    SOLD : 'SOLD',
    CANCELLED : 'CANCELLED'
}

export const TradeAssetType = {
    STOCK : 'STOCK',
    CRYPTO : 'CRYPTO',
    OPTIONS: 'OPTIONS'
}