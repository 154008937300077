import React from 'react';
import { useEffect } from "react";
import YouTube from 'react-youtube';


const videos = [
  {
    youTubeId: "8wkh68LAJO0"
  },
  {
    youTubeId: "qkChxbuUqvU"
  },
  {
    youTubeId: "gzn1MegTqmM"
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/articles/trading/05/playinggaps.asp"
  },
  {
    title: "StockChart School",
    url: "https://school.stockcharts.com/doku.php?id=trading_strategies:gap_trading_strategies"
  },
  {
    title: "Warrior Trading",
    url: "https://www.warriortrading.com/gap-go/"
  }
]

function GapUpDownStrategyPage() {

  console.log("GapUpDownStrategyPage")

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Gap up or down Strategy</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
              <h5>Descriptions</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              Gap trading is a strategy that focuses on the "gaps" in an asset's price. 
              A gap is a sudden change in price between two trading periods, often triggered by news events, earnings reports, or other influential factors. 
              This strategy aims to capitalize on the price movements that follow these gaps.
              </div>
            </div>
            <br/>

            <div className='row'>
              <div className='col-12'>
                A "gap up or down" trading strategy typically involves trading stocks or other securities that have made a significant price gap from the previous day's close. This can happen due to after-hours news events, earnings reports, or other factors that significantly change the market's perception of the security's value. Here's a basic outline of how this strategy can work:

                <ul>
                  <li>
                    <b>Identify the Gap</b>: Use pre-market data to identify stocks that are gapping up or down. A gap up occurs when the current day's opening price is higher than the previous day's closing price. A gap down is the opposite, with the opening price being lower than the previous day's close.

                  </li>
                  <li>
                    <b>Assess the Volume</b>: Look for high trading volume in the gapping stocks as this can indicate strong interest and potentially more price movement.

                  </li>
                  <li>
                    <b>Determine the Cause</b>: Research why the stock is gapping. Is it due to a news event, earnings report, or perhaps sector or market-wide movement?

                  </li>
                  <li>
                    <b>Set Entry Points</b>: Decide when to enter the trade. Some traders look for a pullback after the initial gap, while others may trade immediately if they expect continuation in the same direction of the gap.

                  </li>
                  <li>
                    <b>Set Exit Points</b>: Have clear criteria for when to exit the trade, whether it's a target price, a stop-loss level, or a time-based exit.
                  </li>
                  <li>
                    <b>Risk Management</b>: Never risk more than a small percentage of your trading capital on any single trade.

                  </li>
                  <li>
                    <b>Backtesting and Paper Trading</b>: Before implementing this strategy with real money, backtest it with historical data and/or do some paper trading to see how it works out without financial risk.
                  </li>
                </ul>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <h5>How to trade this Strategy?</h5>
                <ul>
                  <li>
                    On gap up with no reason at all. It will bounce back most likely at opening. Wait for the fake run, use a technical chart pattern to tell the pull back.
                  </li>
                  <li>
                    Same thing with gap down with no clear reason. It will bounce back most likely at opening. Wait for the fake fall, use a technical chart pattern to tell the bounce.
                  </li>

                  <li>
                    Use RSI and VWAP.
                  </li>
                  <li>
                    <strong>Wait for the bounce and sort into it.</strong>
                  </li>
                  <li>
                    <strong>If it's going up, wait for it to pass the off hour high. If it's going down, wait for it to pass off hour low</strong>
                  </li>
                </ul>
              </div>
            </div>
   
            </div>
          </div>


          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default GapUpDownStrategyPage;
