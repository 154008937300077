import './WatchListItem.css';
import React from 'react';
import { useEffect} from "react";

function MyTradingStrategy({ security }) {

  // load data and indicators
  // over bought and over sold
  // indicate down or up daily
  // vwap of 45 minutes
  // change in volume

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div className="row">
        <div className="col-12">
          <ul>
            <li>Trade with the trend</li>
            <li>On reversal, sell quick</li>
            <li>RSI has to be oversold or over bought</li>
            <li>Check VWAP</li>
            <li>Check MACD</li>
            <li>Candle Stick Pattern</li>

          </ul>
        </div>
      </div>
  );
}

export default MyTradingStrategy;
