import { createSlice } from '@reduxjs/toolkit'
import Storage from './storage'

const storeName = "tradeStationPositions"

var positions = Storage.getJson(storeName) ? Storage.getJson(storeName) : {}

export const tradeStationPositionsSlice = createSlice({
  name: storeName,
  initialState: {
    positions: positions
  },
  reducers: {
    addPosition: (state, action) => {

      let position = action.payload

      // console.log("add position ", position)

      state.positions[position['Symbol']] = position

      Storage.setJson(storeName, state.positions)
    },
    removePosition: (state, action) => {

      let position = action.payload

      console.log("delete position ", position)

      delete state.positions[position['Symbol']]

      Storage.setJson(storeName, state.positions)
    }
  },
})

// Action creators are generated for each case reducer function
export const { addPosition, removePosition} = tradeStationPositionsSlice.actions

export default tradeStationPositionsSlice.reducer