import './BuyOptions.css';
import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import { OrderType, TradeDirection } from './enum/OrderEnums';
// import Auth from '../components/Auth';
import StreamerApi from '../api/StreamerApi';
import WebsocketConnector from './WebsocketConnector';
import TradeApi from '../api/TradeApi';
import { useSelector, useDispatch } from 'react-redux'
import { add } from '../store/ActiveTradesReducer';

function BuyOptionQuotes({ legsSymbol, tradeDir, buyEnabled }) {

  const dispatch = useDispatch()

  const stompClient = WebsocketConnector.connect()

  const userInfo = useSelector((state) => state.userInfo.userInfo)

  const [searchParams, ] = useSearchParams();

  const disabledBuy = useSelector((state) => state.userInfo.disabledBuy)

  const ticker = searchParams.get('ticker');

  const [optionQuote, setOptionQuote] = useState({Strikes:[]})

  const [trade, setTrade] = useState({'ticker': ticker, 'legsSymbol': legsSymbol, 'direction':'' ,'buyOrderType': OrderType.LIMIT_ORDER, 'numberOfContracts': 1, 'optionStrikePrice': 0.0, 'buyLimitPrice': 0, maxCost: 0 })

      
  useEffect(() => {

    console.log("BuyOptionQuotes")

    StreamerApi.streamOptionQuotes(ticker, legsSymbol).then((response) => {
      console.log("start option quotes response: ", response);
    }).catch((error) => {
        console.error("Error: ", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legsSymbol]);



  useEffect(() => {

    stompClient.onConnect = (frame) => {
      console.log('option quotes Connected:', frame);

      stompClient.subscribe('/topic/option-quotes/'+ticker+'/'+legsSymbol, (message) => {
        let optionChain = JSON.parse(message.body)
        // console.log("got option quotes from webhook ", optionChain)

        setOptionQuote(optionChain)

        // setTrade({
        //   ...trade,
        //   ['limitPrice']: parseFloat(optionChain.Bid).toFixed(2),
        //   ['maxCost']:(parseFloat(optionChain.Bid) * parseInt(trade.numberOfContracts)).toFixed(2),
        // });

      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legsSymbol]);

  const handleTradeChange = (e) => {

    console.log("handleTradeChange ", e.target.name, e.target.value)

    let newTrade= JSON.parse(JSON.stringify(trade));

    console.log("newTrade ", newTrade)

    newTrade[e.target.name] = e.target.value

    if(e.target.name==='numberOfContracts' || e.target.name==='buyLimitPrice'){
      
      let maxCost = parseFloat(newTrade['buyLimitPrice']) * (newTrade.numberOfContracts * 100)

      console.log("maxCost ", maxCost)

      newTrade.maxCost = maxCost
    }

    setTrade(newTrade);
    
  };

  const submitOrder = (order) => {
    console.log("submitOrder")

    TradeApi.buyOptions(order).then((response) => {
      console.log("buy options response: ", response);

      resetAllFields()

      dispatch(add(response.data))


    }).catch((error) => {
        console.error("Error: ", error);
    });

  }

  const submitLimitOrder = () => {
    console.log("submitLimitOrder")

    let order= JSON.parse(JSON.stringify(trade));

    order['direction'] = (tradeDir==="Put") ? TradeDirection.SHORT : TradeDirection.LONG
    order['legsSymbol'] = legsSymbol

    order.buyOrderType = OrderType.LIMIT_ORDER

    console.log(order)

    submitOrder(order)

  }

  const submitMarketOrder = () => {
    console.log("submitMarketOrder")

    let order= JSON.parse(JSON.stringify(trade));

    order['direction'] = (tradeDir==="Put") ? TradeDirection.SHORT : TradeDirection.LONG
    order['legsSymbol'] = legsSymbol

    order.buyOrderType = OrderType.MARKET_ORDER

    console.log(order)

    submitOrder(order)
  }


  const resetAllFields  = () => {
    setTrade({'ticker': ticker, 'legsSymbol': legsSymbol, 'direction':'' ,'orderType': OrderType.LIMIT_ORDER, 'numberOfContracts': 1, 'optionStrikePrice': 0.0, 'buyLimitPrice': 0, maxCost: 0 })
  }



  return (
    <>
      <div className='row'>
        <div className="col-12 col-sm-12">
          <div className='row mb-2'>
            <div className="col-12 col-sm-3">

              Type: {optionQuote.Side}
              
            </div>
            <div className="col-12 col-sm-3">

              Strike Price: ${parseFloat(optionQuote['Strikes'][0]).toFixed(2)}

            </div>
            <div className="col-12 col-sm-3">

              Symbol: {legsSymbol}

            </div>
            <div className="col-12 col-sm-3">

              Delta: {optionQuote.Delta}

            </div>
          </div>

          <div className='row mb-2'>
            <div className="col-12 col-sm-3">
            Ask: ${parseFloat(optionQuote.Ask).toFixed(2)}
            </div>
            <div className="col-12 col-sm-3">
            Bid: ${parseFloat(optionQuote.Bid).toFixed(2)}
            </div>
            <div className="col-12 col-sm-3">
            Low: {optionQuote.Low}, Medium: {optionQuote.Mid}, High: {optionQuote.High}
            </div>
            <div className="col-12 col-sm-3">
            </div>
          </div>

          <div className='row mb-2'>
            <div className="col-12 col-md-12">
            Daily Open Interest: {optionQuote.DailyOpenInterest}
            </div>
          </div>

          <div className='row mb-2'>
            <div className="col-12 col-md-12">
            <hr/>
            </div>
          </div>

          <div className='row mb-2'>
            <div className="col-12 col-sm-6 border p-1">

              <h5>Limit Order</h5>

              <div className='row mb-2'>
                <div className="col-12 col-sm-2">
                  <b>Ask</b>
                  <br/>
                  ${parseFloat(optionQuote.Ask).toFixed(2)}
                </div>
                <div className="col-12 col-sm-3">
                  <b>Bid</b>
                  <br/>
                  ${parseFloat(optionQuote.Bid).toFixed(2)}
                </div>
                <div className="col-12 col-sm-3">
                  <b>Max Cost</b>
                  <br/>
                  ${trade.maxCost.toFixed(2)}
                </div>
                <div className="col-12 col-sm-4">
                  <b>Buying Power</b>
                  <br/>
                  ${userInfo.balance}
                </div>
              </div>

              <div className='row mb-2'>
                <div className="col-12 col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Limit Price </label>
                    <input 
                    name="buyLimitPrice"
                    value={trade.buyLimitPrice} 
                    onChange={handleTradeChange}
                    type="text" 
                    className="form-control" 
                    
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Contracts</label>
                    <select 
                    name="numberOfContracts"
                    value={trade.numberOfContracts} 
                    onChange={handleTradeChange}
                    className="form-select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>

              </div>

              <div className='row mb-2'>
                <div className="col-12 col-sm-12">

                  {
                    (buyEnabled || disabledBuy) &&
                    <div><b>Already an active trade</b></div>
                  }
                  <button 
                  onClick={()=>submitLimitOrder()}

                  disabled={buyEnabled || disabledBuy}

                  type="button" className="btn btn-outline-primary">Submit</button>
                
                </div>
              </div>
            
            </div>
            <div className="col-12 col-sm-6 border p-1">

              <h5>Market Order</h5>

              <div className='row mb-2'>
                <div className="col-12 col-sm-2">
                  <b>Ask</b>
                  <br/>
                  ${parseFloat(optionQuote.Ask).toFixed(2)}
                </div>
                <div className="col-12 col-sm-3">
                  <b>Bid</b>
                  <br/>
                  ${parseFloat(optionQuote.Bid).toFixed(2)}
                </div>
                <div className="col-12 col-sm-3">
                  <b>Max Cost</b>
                  <br/>
                  ${trade.maxCost.toFixed(2)}
                </div>
                <div className="col-12 col-sm-4">
                  <b>Buying Power</b>
                  <br/>
                  ${userInfo.balance}
                </div>
              </div>

              <div className='row mb-2'>
                <div className="col-12 col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Market Price </label>
                    <input 
                    name="buyLimitPrice"
                    value={parseFloat(optionQuote.Ask).toFixed(2)}
                    type="text" 
                    disabled={true}
                    className="form-control" 
                    
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Contracts</label>
                    <select 
                    name="numberOfContracts"
                    value={trade.numberOfContracts} 
                    onChange={handleTradeChange}
                    className="form-select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>

              </div>

              <div className='row mb-2'>
                <div className="col-12 col-sm-12">

                  {
                    (buyEnabled || disabledBuy) &&
                    <div><b>Already an active trade</b></div>
                  }
                  <button 
                  onClick={()=>submitMarketOrder()}

                  disabled={buyEnabled || disabledBuy}

                  type="button" className="btn btn-outline-primary">Submit</button>
                
                </div>
              </div>
            
            </div>

          </div>

        </div>
      </div>
    </>
  );
}

export default BuyOptionQuotes;
