import { createSlice } from '@reduxjs/toolkit'
import Storage from './storage'

const storeName = "userInfo"

var userInfo = Storage.getJson(storeName) ? Storage.getJson(storeName) : {"balance":0}

const disabledBuyStoreName = "disabledBuy"

var disabledBuy = Storage.get(disabledBuyStoreName) ? JSON.parse(Storage.get(disabledBuyStoreName)) : false

export const userInfoSlice = createSlice({
  name: storeName,
  initialState: {
    userInfo: userInfo,
    disabledBuy: disabledBuy
  },
  reducers: {
    setUser: (state, action) => {

        state.userInfo = action.payload;

        console.log("set new userInfo ", action.payload)

        Storage.setJson(storeName, action.payload)
    },
    setDisabledBuy: (state, action) => {

      state.disabledBuy = action.payload;

      console.log("set new disabledBuy ", action.payload)

      Storage.set(disabledBuyStoreName, action.payload)
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setDisabledBuy } = userInfoSlice.actions

export default userInfoSlice.reducer