import React from 'react';
import {
  useNavigate
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

function StrategySidebar() {

  const navigate = useNavigate();

  const pathUrl = window.location.pathname

  console.log('pathUrl, ', pathUrl)

  const navs = [
    {
      name: 'Price Action',
      urlSlug : 'price-action',
      highlight: pathUrl==="/strategies/price-action"
    },
    {
      name: 'Gap Up/Down',
      urlSlug : 'gap-up-down',
      highlight: pathUrl==="/strategies/gap-up-down"
    },
    {
      name: 'Momentum',
      urlSlug : 'momentum',
      highlight: pathUrl==="/strategies/momentum"
    },
    {
      name: 'Earning Report',
      urlSlug : 'earning',
      highlight: pathUrl==="/strategies/earning"
    },
    {
      name: 'Options',
      urlSlug : 'options',
      highlight: pathUrl==="/strategies/options"
    },
  ]

  return (
    <Nav variant="pills"  defaultActiveKey="/strategies/earning" className="flex-column">

      {
        navs.map((item , index)=>(
          <Nav.Link key={item.name}
            className={`border ${index === navs.length - 1 ? 'border-bottom' : 'border-bottom-0'} ${item.highlight ? 'active' : ''}`}
            onClick={()=>navigate("/strategies/"+item.urlSlug+"?sidebar=strategy")}
          >
            {item.name}
          </Nav.Link>
        ))
      }
      
      {/* <Nav.Link
        className="border border-bottom-0"
        onClick={()=>navigate("/strategies/earning?sidebar=strategy")}
      >
        Earning Report
      </Nav.Link>
      <Nav.Link 
        className="border border-bottom-0"
        onClick={()=>navigate("/strategies/gap-up-down?sidebar=strategy")}
      >
        Gap Up/Down
      </Nav.Link>

      <Nav.Link 
        className="border"
        onClick={()=>navigate("/strategies/momentum?sidebar=strategy")}
      >
        Momentum
      </Nav.Link> */}
      
    </Nav>
  );
}

export default StrategySidebar;
