// import axios from 'axios';
import GraphQLClient from './GraphQLConfig';
import { gql } from '@apollo/client';

// var instance = axios.create({
//     baseURL: process.env.REACT_APP_GRAPHQL_URL
// });

// var bearerToken = process.env.REACT_APP_API_TOKEN

const BigMoveGraphQL = {

    getTodayBigMoves: (date) => {

      const getBigMoves = gql`
        query getBigMoves($date: timestamp!) {
          big_moves(where: {end_date_time: {_gte: $date}}, order_by: {id: desc}) {
            id
            type
            ticker
            viewed
            viewedAt: viewed_at
            volumeDiff: volume_diff
            startVolume: start_volume
            startPrice: start_price
            startDateTime: start_date_time
            priceDiff: price_diff
            highLowDiff: high_low_diff
            endVolume: end_volume
            endPrice: end_price
            endDateTime: end_date_time
          }
        }
      `;


      return GraphQLClient
      .query({
        query: getBigMoves,
        variables: {
          date: date
        },
        fetchPolicy: "no-cache"
      });
    },
    getHistoricalBigMoves: (limit) => {

      const getHistoricalBigMoves = gql`
        query getHistoricalBigMoves($limit: Int!) {
          big_moves(order_by: {id: desc}, limit: $limit) {
            id
            type
            ticker
            viewed
            viewedAt: viewed_at
            volumeDiff: volume_diff
            startVolume: start_volume
            startPrice: start_price
            startDateTime: start_date_time
            priceDiff: price_diff
            highLowDiff: high_low_diff
            endVolume: end_volume
            endPrice: end_price
            endDateTime: end_date_time
          }
        }
      `;


      return GraphQLClient
      .query({
        query: getHistoricalBigMoves,
        variables: {
          limit: limit
        },
        fetchPolicy: "no-cache"
      });
    },
   
}

export default BigMoveGraphQL;