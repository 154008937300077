import './Journal.css';
import { useState , useEffect} from "react";
import NoteApi from '../api/NoteApi';
import { useSearchParams } from 'react-router-dom';
import {
  useNavigate
} from "react-router-dom";
import { FileType } from '../components/enum/FileSlugs';

function JournalEntry() {

  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();

  // Get a specific query parameter
  const uuid = searchParams.get('uuid');

  const [errorMsg, setErrorMsg] = useState("");
  const [entry, setEntry] = useState({content:"",files:[]});


  useEffect(() => {
    loadNote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNote = () => {
    NoteApi.getJournalNoteByUuid(uuid).then((response) => {
      console.log("journal note response: ", response.data);
      setEntry(response.data)
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  const update = (uuid) => {
    let url = "/journal/add-update"
    if(uuid){
      url += "?uuid="+uuid
    }
    navigate(url)
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Journal Entry</h4>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-12'>
              <button
                onClick={()=>update(uuid)}
                type="button" 
                className="btn btn-outline-primary btn-sm">Edit</button>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <b>Date</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {entry.date}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <b>Content</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {entry.content}
            </div>
          </div>
       
          <div className='row'>
            <div className='col-sm-12'>
              <b>Media</b>
              <div className='row'>
                {
                  entry.files.length > 0 &&
                  entry.files.map((file)=>(
                    
                      <div key={file.id} className='col-12 col-sm-4 text-center'>

                        <div className='row'>
                          <div className='col-12'>
                            {file.fileName}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 border'>
                            {
                              file.fileUIType === FileType.IMAGE &&
                              <img src={file.s3GeneratedUrl} className="img-fluid" alt="..."/>
                            }
                            {
                              file.fileUIType === FileType.VIDEO &&
                              <video width="500" height="350" controls>
                                 <source src={file.s3GeneratedUrl} type={file.contentType}></source>
                                 Your browser does not support the video tag.
                              </video>
                            }
                            {
                              file.fileUIType === FileType.PDF &&
                              <iframe 
                              src={file.s3GeneratedUrl} 
                              width="500" 
                              height="350" 
                              title={file.fileName}>
                              </iframe>
                            }
                          </div>
                        </div>
                      </div>
                    
                  ))
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default JournalEntry;
