import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const FileApi = {

    getPresignedUrlToUploadFile: (slug) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/files/s3-presigned-url?slug='+slug, options);
    },
    uploadFiles: (slug, formData) => {

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': user.token
            }
        };
        return instance.post('/files/upload?slug='+slug, formData, options);
    }

}

export default FileApi;