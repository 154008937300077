import React from 'react';
import { useEffect, useState } from "react";
import ZoomImageModal from "../../components/ZoomImageModal";
import YouTube from 'react-youtube';

const images = [
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-bottom/double_bottom_1.png",
    alt: "Double bottom 1",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-bottom/double_bottom_2.png",
    alt: "Double bottom 2",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-bottom/double_bottom_3.png",
    alt: "Double bottom 3",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-bottom/double_bottom_4.jpeg",
    alt: "Double bottom 4",
    explanation:""
  },
  {
    url: process.env.REACT_APP_ASSET_URL+"/chart-patterns/double-bottom/double_bottom_5.jpeg",
    alt: "Double bottom 5",
    explanation:""
  }
]

const videos = [
  {
    youTubeId: "eajHUrv0X7I"
  },
  {
    youTubeId: "YEQpJW3UnEY"
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/terms/d/doublebottom.asp"
  },
  {
    title: "StockChart School",
    url: "https://school.stockcharts.com/doku.php?id=chart_analysis:chart_patterns:double_bottom_reversal"
  },
  {
    title: "Daily Price Action",
    url: "https://dailypriceaction.com/forex-beginners/double-bottom-pattern/"
  },
  {
    title: "Tradingview",
    url: "https://www.tradingview.com/ideas/doublebottom/"
  }
]

function DoubleBottomChartPatternPage() {
  
  console.log("DoubleBottomChartPatternPage")

  const [zoomImage, setZoomImage] = useState({show: false, imageUrl:"",imageAlt:""})

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomInImage = (image) => {
    console.log("zoomInImage, ", image)
    setZoomImage({show: true, imageUrl: image.url, imageAlt: image.alt})
  }

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };



  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h4>Double Bottom Chart Pattern</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
              <h5>Descriptions</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              The Double Bottom pattern is a bullish reversal chart pattern that signifies a possible trend reversal from bearish to bullish. 
              This pattern appears after a prolonged downtrend and is characterized by two consecutive troughs that are roughly equal, separated by a peak in between. 
              The pattern is confirmed when the price breaks above the highest point of the pattern, usually through increased volume.
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-12'>
              <h5>Structure</h5>
              </div>
            </div>

            <ol>
              <li><b>First Top</b>: After a downtrend, the price reaches a low point and reverses upwards.</li>
              <li><b>Trough</b>: The price rises from the first bottom to form a peak.</li>
              <li><b>Second Top</b>: The price then declines again but only to the level of the first bottom, after which it reverses to rise once more.</li>
              <li><b>Confirmation</b>: A bullish trend is confirmed when the price moves past the peak created between the two bottoms.</li>
            </ol>

            <div className='row'>
              <div className='col-12'>
                <h5>Note *</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              The pattern is often used by traders as an indication to buy into the asset in question, but it is crucial to wait for confirmation (typically a strong break above the peak) before doing so.
              </div>
            </div>
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

              <div className='row'>
                <div className='col-12'>
                  <h5>Images</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <small>(click on image to zoom in)</small>
                </div>
              </div>

              <div className='row'>
                {
                  images.map((image) => (
                    <div className='col-12 col-sm-3 cursor-selection' key={image.alt} onClick={()=>zoomInImage(image)}>
                      <img src={image.url} className="img-fluid" alt={image.alt}></img>
                      <div></div>
                      {image.explanation}
                    </div>
                    
                  ))
                }
              </div>    
              <div className='row'>
                <div className='col-12'>
                  <ZoomImageModal 
                    showModal={zoomImage.show} 
                    imageUrl={zoomImage.imageUrl} 
                    imageAlt={zoomImage.imageAlt} 
                    closeModal={()=>(
                      setZoomImage({show: false})
                    )}
                    />
                </div>
              </div>   
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

          <div className='row'>
            <div className='col-12'>
   
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default DoubleBottomChartPatternPage;
