
const StringUtil = {

    truncateString: (str, limit) => {
        if(str === null || str === undefined){
          return ''
        }
        if (str.length > limit) {
          return str.substring(0, limit) + '...';
        } else {
          return str;
        }
    }
}

export default StringUtil;

