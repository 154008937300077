import axios from 'axios';

var instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_INFRA_URL
});

const BackendInfraApi = {

    getServicesStatus: () => {

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return instance.get('/api-services-status', options);
    },
    startApiServices: () => {

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return instance.post('/start-api-services',{}, options);
    },
    startJobService: () => {

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return instance.post('/start-job-service',{}, options);
    },
    stopApiServices: () => {

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return instance.post('/stop-api-services',{}, options);
    },
}

export default BackendInfraApi;