import React from 'react';
import { useEffect } from "react";
import YouTube from 'react-youtube';


const videos = [
  {
    youTubeId: "w_1xHq-OwmQ"
  },
  {
    youTubeId: "-P2QW1Sxt10"
  },
  {
    youTubeId: "RG4qEMp2XcU"
  },
  {
    youTubeId: "vsbSgitdqzU"
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/articles/active-trading/110714/introduction-price-action-trading-strategies.asp"
  },
  {
    title: "Finance Strategy",
    url: "https://www.financestrategists.com/wealth-management/fundamental-vs-technical-analysis/price-action/"
  }
]

function PriceActionStrategyPage() {

  console.log("PriceActionStrategyPage")

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Price Action Strategy</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            Price action trading is a technique that allows a trader to read the market and make subjective trading decisions based on recent and actual price movements, rather than relying solely on technical indicators.
            </div>
          </div>
          <br/>

          <div className='row'>
            <div className='col-12'>
            A price action strategy is a trading method that involves the analysis of basic price movement as a method for financial speculation. Price action is simply how prices change - the action of price. It is often represented and analyzed with candlestick charts. Traders who use this technique believe that all economic variables are represented by and factored into the price movement on a price chart.
            </div>
          </div>

                        
          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                    <b>Understanding Price Movements</b>: 
                      <ul>
                        <li>Recognize that price action reflects all variables affecting a market at any given time.</li>
                        <li>Use real-time price information to make predictions about future movements.</li>
                      </ul>

                    </li>
                    <li>
                      <b>Candlestick Patterns</b>: Identify candlestick patterns which can indicate potential market directions. Examples include the hammer, shooting star, engulfing patterns, and inside bars.
                    </li>
                    <li>
                      <b>Support and Resistance Levels</b>: Determine where the market has historically had a hard time breaking through (resistance) or where it finds support (where the price frequently bounces back from).
                    </li>
                    <li>
                      <b>Trend Analysis</b>: Identify the direction of the market, whether it’s trending upwards, downwards, or sideways (ranging).
                    </li>
                    <li>
                      <b>Breakouts and Breakdowns</b>: A breakout strategy involves entering a position as the price makes a move through a key level of resistance. Conversely, a breakdown strategy involves entering a position as the price drops through a level of support.
                    </li>
                    <li>
                      <b>Volume</b>: Volume can confirm the strength of a price move. Higher volume during a price move indicates a stronger trend.
                    </li>
                    <li>
                      <b>Chart Patterns</b>: Recognize larger scale chart patterns such as triangles, flags, and head and shoulders formations.
                    </li>
                    <li>
                      <b>Price Level Confluence</b>: Look for areas where price action can be predicted with high probability due to support/resistance, Fibonacci levels, or other key levels.
                    </li>
                    <li>
                      <b>Trading Psycholog</b>: Understand the psychological aspect of trading, which can affect the decisions traders make.
                    </li>
                    <li>
                      <b>Risk Management</b>: Always use stop-loss orders and only risk a small percentage of the account on a single trade.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>





          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>When to Use</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Identifying a scenario</b>: Traders identify a scenario, such as a stock price entering a bull phase or a bear phase.
                    </li>
                    <li>
                      <b>Identifying trading opportunities within the scenario</b>: For example, once a stock is in a bull run, is it likely to either overshoot or retreat. Guessing which path the stock price will take is a subjective choice that will vary from one trader to the other, even given the same identical scenario
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

              
          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>Things to Consider</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                    <b>Research</b>: Look at historical earnings, revenue trends, and analysts' expectations.
                    </li>
                    <li>
                      <b>Volatility</b>: Use options data to gauge expected price volatility.
                    </li>
                    <li>
                      <b>Entry Point Pre-Announcement</b>: Enter based on expected price movement due to earnings speculation.
                    </li>
                    <li>
                      <b>Entry Point Post-Announcement</b>: Wait for the announcement and enter based on the market's reaction.
                    </li>
                    <li>
                      <b>Exit Strategy</b>: Use options data to gauge expected price volatility.
                    </li>
                    <li>
                      <b>Risk Management</b>: Use stop-loss orders or options strategies like protective puts to manage downside risk.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default PriceActionStrategyPage;
