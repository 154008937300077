import './GapUpOrDown.css';
import React from 'react';
import { useState , useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import BigMoveGraphQL from '../graphql/BigMoveGraphQL';
import DateTimeUtil from '../utility/DateTimeUtil'

function GapUpOrDown() {

  const [bigMoves, setBigMoves] = useState([{id:0}]);

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    // StrategyApi.getGapUpOrDown().then((response) => {
    //   console.log("getGapUpOrDown response: ", response);

    //   setBigMoves(response.data)
      
    // }).catch((error) => {
    //   console.error("Error: ", error);
    //   setErrorMsg(error.message)
    //   console.error("Error: ", errorMsg);
    // });

    loadTodayBigMoves()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTodayBigMoves = () => { 
    console.log("load today GapUpOrDown");
    BigMoveGraphQL.getTodayBigMoves(DateTimeUtil.getTodayDate()).then((response) => {
      console.log("today GapUpOrDown response: ", response.data);

      let bigMoves = response.data?.big_moves

      if(bigMoves){
        setBigMoves(bigMoves)
      }else{
        loadHistoricalBigMoves()
      }

      
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });

  }

  const loadHistoricalBigMoves = () => {
    console.log("load historical GapUpOrDown");

    BigMoveGraphQL.getHistoricalBigMoves(10).then((response) => {
      console.log("historical GapUpOrDown response: ", response.data);

      let bigMoves = response.data?.big_moves || []

      setBigMoves(bigMoves)

      
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h1>Gap Up/Down</h1>
          <div className="col-12 col-md-12">
            <div className="row">
              { bigMoves.length > 0 &&
                bigMoves.map((bigMove)=>(
                  <div className="col-12 col-md-4 p-3" key={bigMove.id}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 menuCardTitle">
                            <button 
                              onClick={()=>navigate('/stock?ticker='+bigMove.ticker)}
                              type="button" className="btn btn-link btn-sm">{bigMove.ticker}</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Type: {bigMove.type}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Price Diff: ${bigMove.priceDiff}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            End Price: ${bigMove.endPrice}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            End Time: {bigMove.endDateTime}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Start Price: ${bigMove.startPrice}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Start Time: {bigMove.startDateTime}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Volume Diff: {bigMove.volumeDiff}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            Start Volume: {bigMove.startVolume}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            End Volume: {bigMove.endVolume}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GapUpOrDown;
