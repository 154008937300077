// import axios from 'axios';
import Auth from '../components/Auth';
import GraphQLClient from './GraphQLConfig';
import { gql } from '@apollo/client';

// var instance = axios.create({
//     baseURL: process.env.REACT_APP_GRAPHQL_URL
// });

// var bearerToken = process.env.REACT_APP_API_TOKEN

var user = Auth.getAuth()
var userId = user?.id

const NoteGraphQL = {

    getJournalNotes: (offset, limit) => {

      const GET_JOURNAL_NOTES = gql`
        query getJournalNotes($userId: bigint!, $offset: Int!, $limit: Int!)  {
          content: notes(order_by: {date_of: desc}, where: {note_type: {_eq: "JOURNAL"}, user_id: {_eq: $userId}}, offset: $offset, limit: $limit) {
            id
            uuid
            dateOf: date_of
            content
            createdAt: created_at
            updatedAt: updated_at
            type: note_type
          }
          pagination: notes_aggregate(where: {note_type: {_eq: "JOURNAL"}, user_id: {_eq: $userId}}) {
            aggregate {
              totalCount: count(columns: id)
            }
          }
        }
      `;


      return GraphQLClient
        .query({
          query: GET_JOURNAL_NOTES,
          variables: {
            userId: userId,
            offset: offset,
            limit: limit
          },
          fetchPolicy: "no-cache"
        });
    }
   
}

export default NoteGraphQL;