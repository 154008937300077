import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const NoteApi = {

    getJournalNoteByDate: (date) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/notes/journal/note?date='+date, options);
    },
    getJournalNoteByUuid: (uuid) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/notes/note?uuid='+uuid, options);
    },
    getJournalNotes: (pageSize, pageNumber) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/notes/journal?pageSize='+pageSize+'&pageNumber='+pageNumber, options);
    },
    addUpdate: (note) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.post('/notes/add-journal-note', JSON.stringify(note), options);
    },
    createOrGet: (day) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/notes/add-or-get-journal-note?userUuid='+user.uuid+"&day="+day, options);
    },

}

export default NoteApi;