import React from 'react';
import { useState , useEffect} from "react";
import StreamerApi from '../api/StreamerApi';
import WebsocketConnector from './WebsocketConnector';
import TradeApi from '../api/TradeApi';
import { useNavigate } from 'react-router-dom';
import { TradeStatus } from './enum/OrderEnums';
import DateTimeUtil from '../utility/DateTimeUtil';

function ActiveOptionTrade({ trade , reloadHistory }) {

  const stompClient = WebsocketConnector.connect()

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [tradeProgress, setTradeProgress] = useState({currentPrice: 0.0, profit: 0.0, currentTotalPrice: 0.0});

  const [limitPrices, setLimitPrices] = useState({sellLimitPrice: trade.sellLimitPrice, stopLossPrice: trade.stopLossPrice});

  useEffect(() => {

    console.log("ActiveOptionTrade")
    console.log(trade)

    StreamerApi.streamOptionQuotes(trade.ticker, trade.legsSymbol).then((response) => {
      // console.log("start option quotes response: ", response);
    }).catch((error) => {
        console.error("Error: ", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trade]);


  useEffect(() => {

    stompClient.onConnect = (frame) => {
      console.log('option quotes Connected:', frame);

      stompClient.subscribe('/topic/option-quotes/'+trade.ticker+'/'+trade.legsSymbol, (message) => {
        let optionChain = JSON.parse(message.body)
        // console.log("got option quotes from webhook ", optionChain)

        updateOnCurrentPrice(optionChain)

      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trade]);

  const updateOnCurrentPrice = (quoteOptionBar) => {

    let tradeInfo = {}
    tradeInfo.currentPrice = parseFloat(quoteOptionBar.Close).toFixed(2)

    let profit = 0
    if(trade.status==='PURCHASED'){
      if(trade.direction==='LONG'){
        profit = (parseFloat(quoteOptionBar.Ask) * (parseInt(trade.numberOfContracts) * 100)) - parseFloat(trade.purchasedTotalPrice)
      }else{
        profit = parseFloat(trade.purchasedTotalPrice) - (parseFloat(quoteOptionBar.Ask) * (parseInt(trade.numberOfContracts) * 100))
      }
      profit = profit.toFixed(2)

      if(profit<0){
        tradeInfo.profitBackground = 'bg-danger-subtle'
        profit = '-$'+(profit * -1)
      }else{
        tradeInfo.profitBackground = 'bg-success-subtle'
        profit = '$'+profit
      }
    }


    tradeInfo.profit = profit

    tradeInfo.currentTotalPrice = (parseFloat(trade.numberOfContracts) * 100) * parseFloat(quoteOptionBar.Close)

    tradeInfo.currentTotalPrice = tradeInfo.currentTotalPrice.toFixed(2)

    tradeInfo.AskPrice = quoteOptionBar.Ask

    // console.log("tradeInfo, ",tradeInfo, quoteOptionBar.Close)

    // console.log("trade, ",trade)

    setTradeProgress(tradeInfo)
  }

  const sellNow = () => {
    console.log("sell now ", trade)
    let order = {}
    order.uuid = trade.uuid
    order.currentPrice = tradeProgress.currentPrice
    
    TradeApi.sellOptionsNow(order).then((response) => {
      console.log("stream response ",trade.ticker, response);  
      reloadHistory()
    }).catch((error) => {
        console.error("Error: ", error);
    });
  }

  const cancelLimitOrder = () => {
    console.log("cancel limit order ", trade)
    
    TradeApi.cancelLimitOrder(trade.uuid, "").then((response) => {
      console.log("cancellation response ",trade.ticker, response); 
      reloadHistory()
    }).catch((error) => {
        console.error("Error: ", error);
    });
  }

  const setLimitPricesBoundaries = () => {
    console.log("setLimitPricesBoundaries ", limitPrices)

    let payload = {}
    payload.uuid = trade.uuid
    payload.sellLimitPrice = limitPrices.sellLimitPrice
    payload.stopLossPrice = limitPrices.stopLossPrice
    
    TradeApi.updateSellLimitPrices(payload).then((response) => {
      console.log("updateSellLimitPrices response ",trade.ticker, response); 
    }).catch((error) => {
      console.error("Error msg: ", error.message);
      console.error("Error: ", error);

      console.error("error.response.data: ", error.response.data);

      if(error.response.data){
        let msg = error.response.data.message+'.\n\n'

        let errors = error.response.data.errors

        console.error("errors: ", errors);

        for(let i=0;i<errors.length;i++){
          msg += (errors[i]['field']+', '+errors[i]['message']+',\n')
        }

        setErrorMsg(msg)
      }else{
        setErrorMsg(error.message+". Server may be down")
      }
    });
  }

  const changeLimitPrices = (e) => {
    setLimitPrices(limitPrices => ({
      ...limitPrices,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <>
      <div className='row border m-2 p-2'>
        <div className="col-12 col-md-12">
          {/* <div className='row'>
            <div className="col-12 col-md-12">
              <b>Active Trade</b>
            </div>
          </div> */}

            <div className='row'>
              <div className="col-12 col-md-12">
                {
                  errorMsg && 
                  <div className="alert alert-danger">
                    {errorMsg}
                  </div>
                }
              </div>
            </div>


          <div className='row'>
            <div className="col-12 col-md-12">
              <div className='row'>
                <div className="col-12">
                  <div className='row mb-3'>
                    <div className="col-6 col-sm-1">
                      <button 
                        onClick={()=>navigate('/option?ticker='+trade.ticker)}
                        type="button" className="btn btn-link btn-sm">{trade.ticker}</button>
                    </div>
                    
                    <div className="col-6 col-sm-1">
                      <button type="button" 
                      disabled={(trade.status === TradeStatus.PENDING_LIMIT_ORDER)}
                      onClick={()=>sellNow()} className="btn btn-outline-primary btn-sm">Sell Now</button>
                    </div>
                    
                    <div className="col-6 col-sm-2">
                      <b>Option Current Price</b>
                      <br/>
                      ${tradeProgress.currentPrice}
                    </div>

                    {/* <div className={tradeProgress.profit >= 0.0 ? 'col-6 col-sm-2 profit-positive text-center' : 'col-6 col-sm-2 profit-negative text-center'}>
                        <b>Profit</b>
                        <br/>
                        ${tradeProgress.profit}
                    </div> */}
                    <div className={'col-6 col-sm-2 '+tradeProgress.profitBackground+' text-center'}>
                        <b>Profit</b>
                        <br/>
                        {tradeProgress.profit}
                    </div>
                    <div className="col-6 col-sm-1">
                      <button 
                          disabled={(trade.status !== TradeStatus.PENDING_LIMIT_ORDER)}
                        type="button" onClick={()=>cancelLimitOrder()} className="btn btn-outline-primary btn-sm">Cancel</button>
                    </div>
                      <div className="col-6 col-sm-1">
                      <b>Id</b>
                      <br/> 
                      {trade.id}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Status</b>
                      <br/> 
                      {trade.status}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-6 col-sm-2">
                      <b>Current Option Ask Price</b>
                      <br/>
                      ${tradeProgress.AskPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Option Purchased Price</b>
                      <br/>
                      ${trade.optionPurchasedPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Trade Current Total Price</b>
                      <br/>
                      ${tradeProgress.currentTotalPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Buy Limit Price</b>
                      <br/>
                      ${trade.buyLimitPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Sell Limit Price</b>
                      <br/>
                      ${trade.sellLimitPrice}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6 col-sm-2">
                      <b>Purchase Total Price</b>
                      <br/>
                      ${trade.purchasedTotalPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Current Total Price</b>
                      <br/>
                      ${tradeProgress.currentTotalPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Share Purchased Price</b>
                      <br/>
                      ${trade.sharePurchasedPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                    </div>
                   
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6 col-sm-2">
                      <b>Stop Loss Limit Price</b>
                      <br/>
                      ${trade.stopLossLimitPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Stop Loss Price</b>
                      <br/>
                      ${trade.stopLossPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Direction</b>
                      <br/>
                      {trade.direction}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Order Type</b>
                      <br/>
                      {trade.buyOrderType}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    
                    <div className="col-6 col-sm-2">
                      <b>Asset Type</b>
                      <br/>
                      {trade.assetType}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Number of Contracts</b>
                      <br/>
                      {trade.numberOfContracts}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Updated At</b>
                      <br/>
                      {DateTimeUtil.format(trade.updatedAt)}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Created At</b>
                      <br/>
                      {DateTimeUtil.format(trade.createdAt)}
                    </div>
                  </div>
                  <div className='row'>
                    
                    <div className="col-6 col-md-3">
                    </div>
                    <div className="col-6 col-md-3">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='row mt-3'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                <h4>Trade Station Order</h4>
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-sm-12">
                {
                  trade?.tsOrder?.data &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className='row'>
                        <div className="col-12 col-sm-3">
                          <b>Status</b>
                          <br/>
                          {trade?.tsOrder?.data?.status}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Status Description</b>
                          <br/>
                          {trade?.tsOrder?.data?.statusDescription}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Reject Reason</b>
                          <br/>
                          {trade?.tsOrder?.data?.rejectReason}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
              
            </div>
          </div>
          <hr/>
          <div className='row mt-3'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                <h4>Trade Station Position</h4>
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-sm-12">
                {
                  trade?.tsPosition?.data &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className='row'>
                        <div className="col-12 col-sm-3">
                          <b>Today's Profit/Loss</b>
                          <br/>
                          ${trade?.tsPosition?.data?.TodaysProfitLoss}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Unrealized Profit Loss</b>
                          <br/>
                          ${trade?.tsPosition?.data?.UnrealizedProfitLoss}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Last</b>
                          <br/>
                          ${trade?.tsPosition?.data?.Last}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>PositionID</b>
                          <br/>
                          {trade?.tsPosition?.data?.PositionID}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Total Cost</b>
                          <br/>
                          ${trade?.tsPosition?.data?.TotalCost}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>MarketValue</b>
                          <br/>
                          ${trade?.tsPosition?.data?.MarketValue}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Long/Short</b>
                          <br/>
                          {trade?.tsPosition?.data?.LongShort}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Quantity</b>
                          <br/>
                          {trade?.tsPosition?.data?.Quantity}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Timestamp</b>
                          <br/>
                          {trade?.tsPosition?.data?.Timestamp}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>InitialRequirement</b>
                          <br/>
                          {trade?.tsPosition?.data?.InitialRequirement}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
              
            </div>
          </div>

          <hr/>
          <div className='row mt-3'>
            <div className="col-12 col-sm-12">
              <small className='text-primary'>You are allowed to set the Sell Limit Prices one time.</small>
              <div className='row'>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <label className="form-label">Sell Limit Price</label>
                    <input type="number" 
                    name="sellLimitPrice"
                    onChange={(e)=>changeLimitPrices(e)}
                    value={limitPrices.sellLimitPrice}
                    disabled={trade.sellLimitPricesSetCount>0}
                    className="form-control" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <label className="form-label">Stop Loss Price</label>
                    <input type="number" 
                    name="stopLossPrice"
                    onChange={(e)=>changeLimitPrices(e)}
                    value={limitPrices.stopLossPrice}
                    disabled={trade.sellLimitPricesSetCount>0}
                    className="form-control" />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <button 
                      onClick={()=>setLimitPricesBoundaries()}
                      disabled={trade.sellLimitPricesSetCount>0}
                      type="button" className="btn btn-outline-primary">Set Limit Prices</button>
                      {
                        trade.sellLimitPricesSetCount>0 && 
                        <div><small>You have already set the Sell Limit Prices</small></div>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default ActiveOptionTrade;
