import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import StreamerApi from '../api/StreamerApi';
import BuyOptions from '../components/BuyOptions';
import WebsocketConnector from '../components/WebsocketConnector';
import TradeHistoryMinDetail from '../components/TradeHistoryMinDetail';
import {
  useNavigate
} from "react-router-dom";
import ActiveOptionTrade from '../components/ActiveOptionTrade';
import { TradeAssetType } from '../components/enum/OrderEnums';
import SecurityGraphQL from '../graphql/SecurityGraphQL';
import TradeGraphQL from '../graphql/TradeGraphQL';

function OptionPage() {

  const stompClient = WebsocketConnector.connect()

  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();

  // const [rsiIndicator, setRsiIndicator] = useState({ticker:"", price:0.0, status:""});

  const [currentPriceBar, setCurrentPriceBar] = useState({Close:0.0});

  const [historicalTrades, setHistoricalTrades] = useState([]);

  // Get a specific query parameter
  const ticker = searchParams.get('ticker');

  // const [security, setSecurity] = useState({id:0,ticker:""});

  // const [trends, setTrends] = useState([{id:0,ticker:""}]);

  const [errorMsg, setErrorMsg] = useState("");

  const [stockDetails, setStockDetails] = useState({});

  const [tickerActiveTrade, setTickerActiveTrade] = useState({});

  useEffect(() => {

    //remove this
    // setTrends([])

    TradeGraphQL.subscribeToTickerActiveTrade(ticker, TradeAssetType.OPTIONS)
    .subscribe({
      next(result) {
        setTickerActiveTrade(result?.data?.trades?.[0])
      },
      error(err) { console.error('subscribeToTickerActiveTrade err', err); },
    });

    SecurityGraphQL.getSecurityDetails(ticker).then((response) => {
      console.log("stock details graphql response: ", response.data);  
      setStockDetails(response?.data?.securities?.[0])
    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("getSecurityDetails error: ", errorMsg);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    StreamerApi.start(ticker).then((response) => {
        console.log("response: ", response);  
    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });

    loadHistoricalTrades()


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    
    stompClient.onConnect = (frame) => {
      console.log('Connected:', frame);

      stompClient.subscribe('/topic/security-current-price/'+ticker, (message) => {
        // console.log("got bar data from webhook")
        updateOnCurrentPrice(JSON.parse(message.body))
      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOnCurrentPrice  = (currentPrice) => {
    setCurrentPriceBar(currentPrice)
  }

  const loadHistoricalTrades = () => {

    console.log("loading option historical trades data");

    TradeGraphQL.getHistoricalTradesByTicker(ticker, TradeAssetType.OPTIONS).then((response) => {
      console.log("historical option trades data: ", response);  

      let histTrades = response?.data?.trades || []
      setHistoricalTrades(histTrades)

    }).catch((error) => {
        console.error("getHistoricalTradesByTicker error: ", error);
    });
  }


  const reloadHistoricalTrades = () => {
    console.log("reloading historical trades data");  
    loadHistoricalTrades()
  }

  useEffect(() => {
    

    // TrendApi.getLatest(ticker).then((response) => {
    //   console.log("response: ", response);

    //   setTrends(response.data)
      
    // }).catch((error) => {
    //   console.error("Error: ", error);
    //   setErrorMsg(error.message)
    // });

    // fetchRSI()

    // // Set the interval to make the API call every 30 seconds
    // const intervalId = setInterval(fetchRSI, 30000)

    // // Cleanup: Clear the interval when the component is unmounted
    // return () => clearInterval(intervalId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchRSI  = () => {
  //   MarketDataApi.getRSI(ticker).then((response) => {
  //     console.log("response: ", response);

  //     setRsiIndicator(response.data)
      
  //   }).catch((error) => {
  //     console.error("Error: ", error);
  //   });
  // }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className="col-12 col-sm-4">

                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <h4>{stockDetails.name} <small>(Options)</small></h4>
                      <h6>({stockDetails.ticker})</h6>

                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className="btn-group bt-sm">
                        <button 
                          onClick={()=>navigate("/stock?ticker="+ticker)}
                          type="button" 
                          className="btn btn-outline-primary bt-sm">Security</button>
                        <a 
                          target='_blank' 
                          href={process.env.REACT_APP_YAHOO_URL+'/'+ticker+'/options?p='+ticker}
                          rel="noopener noreferrer"
                          type="button" 
                          className="btn btn-outline-primary bt-sm">
                          Yahoo
                        </a>
                        <a 
                          target='_blank' 
                          href={process.env.REACT_APP_GOOGLE_URL+'/'+ticker+':NASDAQ'}
                          rel="noopener noreferrer"
                          type="button" className="btn btn-outline-primary bt-sm">
                          
                          Google
                        </a>
                        <a 
                        target='_blank'
                        href={process.env.REACT_APP_BROKER_OPTION_URL+'/'+ticker}
                        rel="noopener noreferrer"
                        className="btn btn-outline-primary bt-sm">Robinhood</a>

                      </div>

                    </div>
                  </div>
            </div>
            <div className="col-12 col-sm-8">

              <div className='row'>
                <div className="col-12 col-sm-4">
                  <b>Type</b>
                  <br/>
                  {stockDetails.type}
                </div>

                <div className="col-12 col-sm-4">
                  <b>Daily Change Rate</b>
                  <br/>
                  {stockDetails.dailyChangeRate}
                </div>

                <div className="col-12 col-sm-4">
                  <b>Hourly Change Rate</b>
                  <br/>
                  {stockDetails.hourlyChangeRate}
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-sm-12">
                  <b>Description</b>
                  <br/>
                  {stockDetails.description}
                </div>
              </div>

              </div>
          </div>
          {/* Trends */}
          <div className='row mt-4'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12 mb-2">
                  Price: ${currentPriceBar.Close}
              
                </div>
              </div>
              <div className='row mb-3'>
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <b>Options</b>
                    </div>
                    <div className="card-body">
                      <BuyOptions currentPriceBar={currentPriceBar} buyEnabled={tickerActiveTrade} />
                    </div>
                  </div>
                  
                </div>
              </div>
        
              <div className='row mb-2'>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <b>Active Option Trade</b>
                    </div>
                    <div className="card-body">
                    {
                      tickerActiveTrade && Object.keys(tickerActiveTrade).length>0 &&
                      <ActiveOptionTrade trade={tickerActiveTrade} reloadHistory={reloadHistoricalTrades} />
                    }
                    </div>
                  </div>
                
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <b>History</b>
                    </div>
                    <div className="card-body">
                    {
                      historicalTrades.length>0 &&
                      historicalTrades.map(trade => (
                        <div className='row' key={trade.id}>
                          <div className="col-12">
                            <TradeHistoryMinDetail trade={trade} />
                          </div>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OptionPage;
