import './Footer.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function FooterLinks() {
  const navigate = useNavigate();

  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const firstSegment = pathSegments[0] || '';
  
  const tabs = [
    {
      name: 'Indicators',
      urlSlug : 'indicators',
      sidebar: 'indicator',
      highlight: firstSegment==="indicators"
    },
    {
      name: 'Strategies',
      urlSlug : 'strategies',
      sidebar: 'strategy',
      highlight: firstSegment==="strategies"
    },
    {
      name: 'Chart Patterns',
      urlSlug : 'chart-patterns',
      sidebar: 'chart-pattern',
      highlight: firstSegment==="chart-patterns"
    }
  ]

  const goTo = (urlSlug, sidebar) => {
    navigate("/"+urlSlug+"?sidebar="+sidebar)
  }

  return (
      <div className="row">
        <div className="col-12">
          
        {
          tabs.map((tab)=>(
            <button key={tab.name}
              onClick={()=>goTo(tab.urlSlug, tab.sidebar)}
              type='button'
              className={`btn btn-outline-primary btn-sm ${tab.highlight ? 'active' : ''}`}
              >
                {tab.name}
              </button>
          ))
        }
        </div>
      </div>
  );
}

export default FooterLinks;
