import React from 'react';
// import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ZoomImageModal({showModal, imageUrl, imageAlt, closeModal }) {

  console.log("ZoomImageModal, showModal=", showModal)

  return (
    <>
      {
        <Modal 
          backdrop={true}
          centered={true}
          fullscreen={true} 
          show={showModal} 
          onHide={closeModal}>
          <Modal.Header>
            <Modal.Title>
              <div className='row'>
                <div className='col-12 text-center'>
                  {imageAlt}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

         
            <div className='row'>
              <div className='col-12 text-center'>
                <img src={imageUrl} className="img-fluid" alt={imageAlt}></img>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}

export default ZoomImageModal;
