import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const TradeApi = {

    buyStocks: (order) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.post('/trades/buy/securities', order, options);
    },

    buyOptions: (order) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.post('/trades/buy/options', order, options);
    },
    getAllActiveTrades: () => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/trades/all', options);
    },
    getActiveTradeByTicker: (ticker) => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/trades/current?ticker='+ticker, options);
    },
    getByUuid: (uuid) => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/trades/by-uuid?uuid='+uuid, options);
    },
    getNonActiveTradesByTicker: (ticker) => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/trades/historical?ticker='+ticker, options);
    },
    getAllNonActiveTrades: () => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/trades/historical/all', options);
    },
    sellNow: (order) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.put('/trades/sell-now', order, options);
    },
    sellOptionsNow: (order) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.put('/trades/sell-options-now', order, options);
    },
    addNote: (uuid, note) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.post('/trades/'+uuid+'/add-note', note, options);
    },
    submitRating: (uuid, star) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.post('/trades/'+uuid+'/rating?rating='+star, {}, options);
    },
    cancelLimitOrder: (uuid, reason) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.put('/trades/'+uuid+'/cancel?reason='+reason, {}, options);
    },
    updateSellLimitPrices: (trade) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.put('/trades/update-sell-limit-prices', trade, options);
    },
}

export default TradeApi;