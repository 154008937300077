import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const StreamerApi = {

    start: (ticker) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };

        const payload = {}

        return instance.post('/streamers/bars/start?ticker='+ticker, payload, options);
    },
    keepAlive: (ticker) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        const payload = {}
        return instance.post('/streamers/bars/keep-alive?ticker='+ticker, payload, options);
    },
    stop: (ticker) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        const payload = {}
        return instance.post('/streamers/bars/stop?ticker='+ticker, payload, options);
    },
    streamOptionChains: (ticker) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        const payload = {}
        return instance.post('/streamers/options/start-chain?ticker='+ticker, payload, options);
    },
    streamOptionQuotes: (ticker, legSymbol) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        const payload = {}
        return instance.post('/streamers/options/quotes?ticker='+ticker+'&legsSymbol='+legSymbol, payload, options);
    }
}

export default StreamerApi;