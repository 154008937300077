import React from 'react';
import { useState , useEffect} from "react";
import {
  useNavigate
} from "react-router-dom";
import SecurityGraphQL from '../graphql/SecurityGraphQL';
import DateTimeUtil from '../utility/DateTimeUtil';

function EarningReportPage() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [todayReports, setTodayReports] = useState([]);

  const [pastReports, setPastReports] = useState([]);

  const [futureReports, setFutureReports] = useState([]);


  useEffect(() => {

    console.log("EarningReportPage")

    loadReports()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadReports = () => {

    let yesterdayDay = DateTimeUtil.getYesterdayDate()
    let nextWeek = DateTimeUtil.getDate(10);

    console.log("yesterdayDay, ", yesterdayDay)
    console.log("nextWeek, ", nextWeek)

    SecurityGraphQL.getEarningReports(yesterdayDay, nextWeek).then((response) => {
      console.log("earning reports response: ", response.data);  

      let stocks = response?.data?.securities || []

      let now = new Date()
      let pastReports = []
      let todayReports = []
      let futureReports = []

      for (let index = 0; index < stocks.length; index++) {
        const stock = stocks[index];


        if(DateTimeUtil.isSameDay(new Date(stock.reportDate),now)){
          todayReports.push(stock)
        }else if(new Date(stock.reportDate) < now){
          pastReports.push(stock)
        }else if(new Date(stock.reportDate) > now){
          futureReports.push(stock)
        }
      }

      setPastReports(pastReports)
      setTodayReports(todayReports)
      setFutureReports(futureReports)

      console.log("pastReports: ", pastReports);  

      console.log("todayReports: ", todayReports);  

      console.log("futureReports: ", futureReports);  


    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });

  }
 
  

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className="col-12 col-sm-12">

              <h5>Earning Reports</h5>
            </div>
          </div>

          <div className='row mt-3 mb-3'>
            <div className="col-12 col-sm-12">

              <div className="card">
                <div className="card-header">
                  Today's Reports
                </div>
                <div className="card-body">

                  <div className='row'>

                    {
                      todayReports.map((stock)=>(
                        <div className="col-12 col-sm-3 mb-1" key={stock.id}>

                          <div className='row'>
                            <div className="col-12 col-sm-12">

                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">{stock.name}</h5>
                                  <h6 className="card-subtitle mb-2 text-body-secondary">
                                    <button 
                                      onClick={()=>navigate("/stock?ticker="+stock.ticker)}
                                      type="button" 
                                      className="btn btn-link m-0 p-0">
                                    ({stock.ticker})
                                    </button>
                                  </h6>
                                  
                                  <div className='row'>
                                      <div className="col-12 col-sm-12">

                                        <div className='row'>
                                          <div className="col-12 col-sm-12">
                                            {stock.reportDate}
                                          </div>
                                        </div>
                                      </div>
                                  </div>

                                  <div className='row'>
                                    <div className="col-12 col-sm-12">
                                      <div className='row'>
                                        <div className="col-12 col-sm-12">
                                          <b>Tags</b>
                                        </div>
                                      </div>
                                      <div className='row mt-2'>
                                        <div className="col-12 col-sm-12">
                                          {
                                            stock.tags.map((tagObj)=>(
                                              <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tagObj.tag}>{tagObj.tag}</span>
                                            ))
                                          }
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      ))
                    }

                  </div>
                  
                </div>
              </div>

            </div>
          </div>

          <div className='row mt-4'>
            <div className="col-12 col-sm-12">
              
              <div className="card">
                <div className="card-header">
                  Past Reports
                </div>
                <div className="card-body">
                  <div className='row'>
                    {
                      pastReports.map((stock)=>(
                        <div className="col-12 col-sm-3 mb-1" key={stock.id}>

                          <div className='row'>
                            <div className="col-12 col-sm-12">

                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">{stock.name}</h5>
                                  <h6 className="card-subtitle mb-2 text-body-secondary">
                                    <button 
                                      onClick={()=>navigate("/stock?ticker="+stock.ticker)}
                                      type="button" 
                                      className="btn btn-link m-0 p-0">
                                    ({stock.ticker})
                                    </button>
                                  </h6>
                                  
                                  <div className='row'>
                                      <div className="col-12 col-sm-12">

                                        <div className='row'>
                                          <div className="col-12 col-sm-12">
                                            {stock.reportDate}
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className='row'>
                                    <div className="col-12 col-sm-12">
                                      <div className='row'>
                                        <div className="col-12 col-sm-12">
                                          <b>Tags</b>
                                        </div>
                                      </div>
                                      <div className='row mt-2'>
                                        <div className="col-12 col-sm-12">
                                          {
                                            stock.tags.map((tagObj)=>(
                                              <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tagObj.tag}>{tagObj.tag}</span>
                                            ))
                                          }
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      ))
                    }

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='row mt-3'>
            <div className="col-12 col-sm-12">

              <div className="card">
                <div className="card-header">
                  Future's Reports
                </div>
                <div className="card-body">

                  <div className='row'>

                    {
                      futureReports.map((stock)=>(
                        <div className="col-12 col-sm-3 mb-1" key={stock.id}>

                          <div className='row'>
                            <div className="col-12 col-sm-12">

                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">{stock.name}</h5>
                                  <h6 className="card-subtitle mb-2 text-body-secondary">
                                    <button 
                                      onClick={()=>navigate("/stock?ticker="+stock.ticker)}
                                      type="button" 
                                      className="btn btn-link m-0 p-0">
                                    ({stock.ticker})
                                    </button>
                                  </h6>
                                  
                                  <div className='row'>
                                      <div className="col-12 col-sm-12">

                                        <div className='row'>
                                          <div className="col-12 col-sm-12">
                                            {stock.reportDate}
                                          </div>
                                        </div>
                                      </div>
                                  </div>

                                  <div className='row'>
                                    <div className="col-12 col-sm-12">
                                      <div className='row'>
                                        <div className="col-12 col-sm-12">
                                          <b>Tags</b>
                                        </div>
                                      </div>
                                      <div className='row mt-2'>
                                        <div className="col-12 col-sm-12">
                                          {
                                            stock.tags.map((tagObj)=>(
                                              <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tagObj.tag}>{tagObj.tag}</span>
                                            ))
                                          }
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      ))
                    }

                  </div>

                </div>
              </div>

            </div>
          </div>

          

        </div>
      </div>
    </>
  );
}

export default EarningReportPage;
