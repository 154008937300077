import './BuyOptions.css';
import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import TradeApi from '../api/TradeApi';
import { TradeAssetType, TradeDirection, OrderType } from './enum/OrderEnums';
import { useSelector } from 'react-redux'
import ApiError from './ApiError';

function BuySecurities({ currentPriceBar, buyEnabled }) {

  const [searchParams,] = useSearchParams();

  // Get a specific query parameter
  const ticker = searchParams.get('ticker');

  const [marketLongOrder, setMarketLongOrder] = useState({price: currentPriceBar.Close, shares: 5});

  const [marketShortOrder, setMarketShortOrder] = useState({price: currentPriceBar.Close, shares: 5});

  const [limitLongOrder, setLimitLongOrder] = useState({price: 0, shares: 5});

  const [limitShortOrder, setLimitShortOrder] = useState({price: 0, shares: 5});

  const [apiError, setApiError] = useState("");

  const [trade, setTrade] = useState({'ticker': ticker, 'direction':'' ,'buyOrderType': '', 'assetType': TradeAssetType.STOCK, 'numberOfShares': 1, 'numberOfContracts': 0, 'sharePurchasedPrice': 0.0 });

  const disabledBuy = useSelector((state) => state.userInfo.disabledBuy)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOrderSizesChange = (e) => {
  //   setOrderSizes({
  //     ...orderSizes,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleLimitLongOrderChange = (e) => {
    setLimitLongOrder({
      ...limitLongOrder,
      [e.target.name]: e.target.value,
    });
  };

  const handleLimitShortOrderChange = (e) => {
    setLimitShortOrder({
      ...limitShortOrder,
      [e.target.name]: e.target.value,
    });
  };

  const handleMarketLongOrderChange = (e) => {
    setMarketLongOrder({
      ...marketLongOrder,
      [e.target.name]: e.target.value,
    });
  };

  const handleMarketShortOrderChange = (e) => {
    setMarketShortOrder({
      ...marketShortOrder,
      [e.target.name]: e.target.value,
    });
  };

  const buy  = (buyOrderType, direction) => {
    console.log(`buy orderType=${buyOrderType} direction=${direction}`)

    setTrade(prevTrade => ({
      ...prevTrade,
      direction: direction,
      buyOrderType: buyOrderType,
    }));

    trade.buyOrderType = buyOrderType
    trade.direction = direction

    if(buyOrderType===OrderType.MARKET_ORDER){
      trade.sharePurchasedPrice = currentPriceBar.Close
    }

    trade.numberOfShares = getOrderSize(buyOrderType, direction)

    console.log("order")
    console.log(trade)

    trade.ticker = ticker
    trade.assetType = TradeAssetType.STOCK

    TradeApi.buyStocks(trade).then((response) => {
      console.log("response: ", response);
      resetAllFields()
      setApiError("")
    }).catch((error) => {
      console.error("Error: ", error);
      setApiError(error)
    });

  }

  const getOrderSize = (orderType, direction) => {
    console.log(`getOrderSize orderType=${orderType} direction=${direction}`)
    let numberOfShares = 0

    if(orderType===OrderType.MARKET_ORDER){

      if(direction===TradeDirection.LONG){
        numberOfShares = marketLongOrder.shares
      }else{
        numberOfShares = marketShortOrder.shares
      }
    }else{
      if(direction===TradeDirection.LONG){
        numberOfShares = limitLongOrder.shares
      }else{
        numberOfShares = limitShortOrder.shares
      }
    }

    return numberOfShares
    
  };

  const resetAllFields  = () => {
    setLimitLongOrder({price: 0, shares: 5})
    setLimitShortOrder({price: 0, shares: 5})
    setMarketLongOrder({price: 0, shares: 5})
    setMarketShortOrder({price: 0, shares: 5})
  }

  const buyLimit = (direction) => {
    console.log(`buyLimit`)

    let order = {}

    order.buyOrderType = OrderType.LIMIT_ORDER
    order.direction = direction

    if(direction===TradeDirection.LONG){

      order.buyLimitPrice = limitLongOrder.price
      order.numberOfShares = limitLongOrder.shares

    }else if(direction===TradeDirection.SHORT){

      order.buyLimitPrice = limitShortOrder.price
      order.numberOfShares = limitShortOrder.shares

    }

    console.log("order")
    console.log(order)

    order.ticker = ticker
    order.assetType = TradeAssetType.STOCK

    TradeApi.buyStocks(order).then((response) => {
      console.log("response: ", response);
      resetAllFields()
      setApiError("")
    }).catch((error) => {
      console.error("Error: ", error);
      console.error("Error msg: ", error.message);
      setApiError(error)

    });
  }

  // const buyLimitLong  = () => {
  //   console.log(`buyLimitLong`)

  //   let order = {}

  //   order.buyOrderType = OrderType.LIMIT_ORDER
  //   order.direction = TradeDirection.LONG
  //   order.buyLimitPrice = limitLongOrder.price
  //   order.numberOfShares = limitLongOrder.shares

  //   submitOrder(order).then((response) => {
  //     console.log("response: ", response);
  //     resetAllFields()
  //     dispatch(add(response.data))
  //   }).catch((error) => {
  //       console.error("Error: ", error);
  //   });
  // }

  // const buyLimitShort  = () => {
  //   console.log(`buyLimitShort`)

  //   let order = {}

  //   order.buyOrderType = OrderType.LIMIT_ORDER
  //   order.direction = TradeDirection.SHORT
  //   order.buyLimitPrice = limitShortOrder.price
  //   order.numberOfShares = limitShortOrder.shares

  //   submitOrder(order).then((response) => {
  //     console.log("response: ", response);
  //     resetAllFields()
  //     dispatch(add(response.data))
  //   }).catch((error) => {
  //       console.error("Error: ", error);
  //   });
  // }

  // const submitOrder = (order) => {
    
  //   console.log("order")
  //   console.log(order)

  //   order.ticker = ticker
  //   order.assetType = TradeAssetType.STOCK

  //   return TradeApi.buyStocks(order);
  // }

  return (
    <>
      <div className='row'>
        <div className="col-12 col-sm-12">
          {
            apiError && 
            <ApiError axiosApiError={apiError} />
          }

          <div className='row mb-2'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-11 text-center">
                  <u><b>Market Order</b></u>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-sm-5 border p-2">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                      <b>Long</b> ${currentPriceBar.Close}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-sm-2">
                      
                    </div>
                    <div className="col-12 col-sm-5">
                      Market Price
                    </div>
                    <div className="col-12 col-sm-5">
                      Shares
                    </div>
                  </div>
                  <div className='row p-2'>
                    <div className="col-12 col-md-2">
                      <button 
                      onClick={()=>buy(OrderType.MARKET_ORDER, TradeDirection.LONG)} 
                      disabled={buyEnabled || disabledBuy}
                      type="button" 
                      className="btn btn-outline-primary btn-sm">Buy</button>
                    </div>
                    <div className="col-12 col-sm-5">
                      <input className="form-control" type="number" 
                        name="price"
                        disabled={true}
                        onChange={handleMarketLongOrderChange}
                        value={currentPriceBar.Close || 0} 
                      />
                    </div>
                    <div className="col-12 col-sm-5">
                      {/* <input className="form-control" type="number" 
                        name="shares"
                        onChange={handleMarketLongOrderChange}
                        value={marketLongOrder.shares || 0} 
                      /> */}

                    <select 
                      name="shares"
                      value={marketLongOrder.shares || 5} 
                      onChange={handleMarketLongOrderChange}
                      className="form-select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">34</option>
                      <option value="50">50</option>
                    </select>

                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-1">

                </div>
                <div className="col-12 col-sm-5 border p-2">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                      <b>Short</b> ${currentPriceBar.Close}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      
                    </div>
                    <div className="col-12 col-md-5">
                      Market Price
                    </div>
                    <div className="col-12 col-md-5">
                      Shares
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      <button 
                        onClick={()=>buy(OrderType.MARKET_ORDER, TradeDirection.SHORT)} 
                        type="button" 
                        disabled={buyEnabled|| disabledBuy}
                        className="btn btn-outline-primary btn-sm">Buy</button> 
                    </div>
                    <div className="col-12 col-sm-5">
                      <input 
                        className="form-control" 
                        type="number" 
                        name="price" 
                        disabled={true}
                        onChange={handleMarketShortOrderChange}
                        value={currentPriceBar.Close || 0}
                      />
                    </div>
                    <div className="col-12 col-md-5">
                      {/* <input 
                        className="form-control form-control-sm" 
                        type="number" 
                        name="shares" 
                        onChange={handleMarketShortOrderChange}
                        value={marketShortOrder.shares || 0}
                      /> */}

                      <select 
                      name="shares"
                      value={marketShortOrder.shares || 5} 
                      onChange={handleMarketShortOrderChange}
                      className="form-select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">34</option>
                      <option value="50">50</option>
                    </select>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3 mb-2'>
            <div className="col-12 col-md-12">
              <div className='row'>
                <div className="col-12 col-sm-11 text-center">
                  <u><b>Limit Order</b></u>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-sm-5 border p-2">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                      <b>Long</b> ${currentPriceBar.Close}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      
                    </div>
                    <div className="col-12 col-md-5">
                      Limit Price
                    </div>
                    <div className="col-12 col-md-5">
                      Shares
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      <button 
                        onClick={()=>buyLimit(TradeDirection.LONG)}
                        type="button" 
                        disabled={buyEnabled || disabledBuy}
                        className="btn btn-outline-primary btn-sm">Buy</button>
                    </div>
                    <div className="col-12 col-sm-5">
                      <input className="form-control" type="number" 
                        name="price" 
                        onChange={handleLimitLongOrderChange}
                        value={limitLongOrder.price}
                      />
                    </div>
                    <div className="col-12 col-md-5">
                      {/* <input className="form-control form-control-sm" type="number" 
                        name="shares" 
                        onChange={handleLimitLongOrderChange}
                        value={limitLongOrder.shares || 0}
                      /> */}

                      <select 
                        name="shares"
                        value={limitLongOrder.shares || 5} 
                        onChange={handleLimitLongOrderChange}
                        className="form-select">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">34</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>
                
                </div>
                <div className="col-12 col-sm-1">

                </div>
                <div className="col-12 col-sm-5 border p-2">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                      <b>Short</b> ${currentPriceBar.Close}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      
                    </div>
                    <div className="col-12 col-md-5">
                      Limit Price
                    </div>
                    <div className="col-12 col-md-5">
                      Shares
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-md-2">
                      <button 
                        onClick={()=>buyLimit(TradeDirection.SHORT)}
                        disabled={buyEnabled || disabledBuy}
                        type="button" className="btn btn-outline-primary btn-sm">Buy</button> 
                    </div>
                    <div className="col-12 col-md-5">
                        <input className="form-control" type="number" 
                          name="price" 
                          onChange={handleLimitShortOrderChange}
                          value={limitShortOrder.price}
                        />
                    </div>
                    <div className="col-12 col-sm-5">
                      {/* <input className="form-control form-control-sm" type="number" 
                        name="shares" 
                        onChange={handleLimitShortOrderChange}
                        value={limitShortOrder.shares || 0}
                      /> */}

                      <select 
                        name="shares"
                        value={limitShortOrder.shares || 5} 
                        onChange={handleLimitShortOrderChange}
                        className="form-select">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">34</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuySecurities;
