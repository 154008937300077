import React from 'react';
import { useState , useEffect} from "react";
import StreamerApi from '../api/StreamerApi';
import { useSelector } from 'react-redux'
import WebsocketConnector from './WebsocketConnector';
import TradeApi from '../api/TradeApi';
import { useNavigate } from 'react-router-dom';
import { TradeStatus } from './enum/OrderEnums';

function ActiveTrade({ trade, reloadHistory }) {

  const navigate = useNavigate();

  const stompClient = WebsocketConnector.connect()

  const [limitPrices, setLimitPrices] = useState({sellLimitPrice: trade.sellLimitPrice, stopLossPrice: trade.stopLossPrice});

  const [tradeProgress, setTradeProgress] = useState({currentPrice: 0.0, profit: 0.0, currentTotalPrice: 0.0});

  const tradeStationPositions = useSelector((state) => state.tradeStationPositions.positions)

  // console.log('tradeStationPositions, ', tradeStationPositions)

  useEffect(() => {
    // console.log("ActiveTrade "+JSON.stringify(trade))
    console.log("start streamer for "+trade.ticker)
    StreamerApi.start(trade.ticker).then((response) => {
      console.log("stream response ",trade.ticker, response);  
    }).catch((error) => {
      console.error("Error: ", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    stompClient.onConnect = (frame) => {
      // console.log('current price connected:', trade.ticker, frame);

      stompClient.subscribe('/topic/security-current-price/'+trade.ticker, (message) => {
        let price = JSON.parse(message.body)
        // console.log("got current price data ", trade.ticker, price)
        updateOnCurrentPrice(price)
      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOnCurrentPrice = (currentPriceBar) => {

    let tradeInfo = {}
    tradeInfo.currentPrice = currentPriceBar.Close

    let profit = 0

    if(trade.status==='PURCHASED'){
      if(trade.direction==='LONG'){
        profit = (parseFloat(currentPriceBar.Close) * parseFloat(trade.numberOfShares)) - parseFloat(trade.purchasedTotalPrice)
      }else{
        profit = parseFloat(trade.purchasedTotalPrice) - (parseFloat(currentPriceBar.Close) * parseFloat(trade.numberOfShares))
      }
      profit = profit.toFixed(2)

      if(profit<0){
        tradeInfo.profitBackground = 'bg-danger-subtle'
        profit = '-$'+(profit * -1)
      }else{
        tradeInfo.profitBackground = 'bg-success-subtle'
        profit = '$'+profit
      }
      
    }

    tradeInfo.profit = profit

    tradeInfo.currentTotalPrice = parseFloat(trade.numberOfShares) * parseFloat(currentPriceBar.Close)

    tradeInfo.currentTotalPrice = tradeInfo.currentTotalPrice.toFixed(2)

    setTradeProgress(tradeInfo)
  }

  const sellNow = () => {
    console.log("sell now ", trade)
    let order = {}
    order.uuid = trade.uuid
    order.currentPrice = tradeProgress.currentPrice
    
    TradeApi.sellNow(order).then((response) => {
      console.log("sell now response ",trade.ticker, response);
      reloadHistory()
    }).catch((error) => {
        console.error("Error with sell now: ", error);
    });
  }

  const cancelLimitOrder = () => {
    console.log("cancel limit order ", trade)
    
    TradeApi.cancelLimitOrder(trade.uuid, "").then((response) => {
      console.log("cancellation response ",trade.ticker, response); 
      reloadHistory()
    }).catch((error) => {
        console.error("Error: ", error);
    });
  }

  const updateSellLimitPrices = () => {
    console.log("updateSellLimitPrice ", limitPrices)

    let payload = {}
    payload.uuid = trade.uuid
    payload.sellLimitPrice = limitPrices.sellLimitPrice
    payload.stopLossPrice = limitPrices.stopLossPrice

    
    TradeApi.updateSellLimitPrices(payload).then((response) => {
      console.log("updateSellLimitPrices response ",trade.ticker, response);
    }).catch((error) => {
        console.error("Error: ", error);
    });
  }

  const changeLimitPrices = (e) => {
    setLimitPrices(limitPrices => ({
      ...limitPrices,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <>
      <div className='row border m-2 p-2'>
        <div className="col-12 col-md-12">
          {/* <div className='row'>
            <div className="col-12 col-md-12">
              <b>Active Trade</b>
            </div>
          </div> */}
          <div className='row'>
            <div className="col-12 col-md-12">
              <div className='row'>
                <div className="col-12">
                {/* {
                      JSON.stringify(trade) 
                    } */}
                  <div className='row'>
                    <div className="col-6 col-sm-2">
                      <button 
                        onClick={()=>navigate('/stock?ticker='+trade.ticker)}
                        type="button" className="btn btn-link btn-sm">{trade.ticker}</button>
                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Share Current Price</b>
                      <br/>
                      ${tradeProgress.currentPrice}

                    </div>
                    <div className={'col-6 col-sm-2 '+tradeProgress.profitBackground+' text-center'}>
                        <b>Profit</b>
                        <br/>
                        {tradeProgress.profit}
                    </div>
                    <div className="col-6 col-sm-2 text-center">
                      <button type="button" 
                      
                      onClick={()=>sellNow()} 
                      disabled={(trade.status === TradeStatus.PENDING_LIMIT_ORDER) }
                      className="btn btn-outline-primary btn-sm me-2">Sell Now</button>
                      
                      <button 
                        disabled={(trade.status !== TradeStatus.PENDING_LIMIT_ORDER) }
                        type="button" onClick={()=>cancelLimitOrder()} className="btn btn-outline-primary btn-sm">Cancel</button>
                    </div>
                    <div className="col-6 col-sm-1">
                      <b>Id</b>
                      <br/>
                      {trade.id}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Status</b>
                      <br/>
                      {trade.status}
                    </div>
                    
                  </div>
           
                  <div className='row mt-2'>
                    <div className="col-6 col-sm-3">
                      Purchase Total Price: ${trade.purchasedTotalPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                      Current Total Price: ${tradeProgress.currentTotalPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                      Share Purchased Price: ${trade.sharePurchasedPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                      Sell Limit Price: ${trade.sellLimitPrice}
                    </div>
                   
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6 col-sm-3">
                      Buy Limit Price: ${trade.buyLimitPrice}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-6 col-sm-3">
                      Stop Loss Limit Price: ${trade.stopLossLimitPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                      Stop Loss Price: ${trade.stopLossPrice}
                    </div>
                    <div className="col-6 col-sm-3">
                      Direction: {trade.direction}
                    </div>
                    <div className="col-6 col-sm-3">
                      Order Type: {trade.buyOrderType}
                    </div>
                  </div>
                  <div className='row'>
                    
                    <div className="col-6 col-sm-3">
                      Asset Type: {trade.assetType}
                    </div>
                    <div className="col-6 col-sm-3">
                      Number of Shares: {trade.numberOfShares}
                    </div>
                    <div className="col-6 col-sm-3">
                    Created At: {trade.createdAt}
                    </div>
                    <div className="col-6 col-sm-3">
                      Updated At: {trade.updatedAt}
                    </div>
                  </div>
                  <div className='row'>
                    
                    <div className="col-6 col-md-3">
                    </div>
                    <div className="col-6 col-md-3">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='row mt-3'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                <h4>Trade Station Position</h4>
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-sm-12">
                {
                  tradeStationPositions[trade.ticker] &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      {/* <div className='row'>
                        <div className="col-12 col-sm-12">
                        {
                          JSON.stringify(tradeStationPositions[trade.ticker])
                        }
                        </div>
                      </div> */}

                      <div className='row'>
                        <div className="col-12 col-sm-3">
                          <b>Today's Profit/Loss</b>
                          <br/>
                          ${tradeStationPositions[trade.ticker].TodaysProfitLoss}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Last</b>
                          <br/>
                          ${tradeStationPositions[trade.ticker].Last}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>PositionID</b>
                          <br/>
                          {tradeStationPositions[trade.ticker].PositionID}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>AveragePrice</b>
                          <br/>
                          ${tradeStationPositions[trade.ticker].AveragePrice}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
              <div className='row mt-2'>
                <div className="col-12 col-sm-12">
                {
                  trade.tsSellLimitOrder && trade.tsSellLimitOrder.data &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className='row'>
                        <div className="col-12 col-sm-3">
                          <b>Order Type</b>
                          <br/>
                          Sell - {trade.tsSellLimitOrder.data.orderType}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Limit Price</b>
                          <br/>
                          ${trade.tsSellLimitOrder.data.limitPrice}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Status</b>
                          <br/>
                          {trade.tsSellLimitOrder.data.status},  {trade.tsSellLimitOrder.data.statusDescription}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Duration</b>
                          <br/>
                          {trade.tsSellLimitOrder.data.duration},   {trade.tsSellLimitOrder.data.goodTillDate}
                        </div>
                    
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
              <div className='row mt-2'>
                <div className="col-12 col-sm-12">
                {
                  trade.tsStopLossOrder && trade.tsStopLossOrder.data &&
                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className='row'>
                        <div className="col-12 col-sm-3">
                          <b>Order Type</b>
                          <br/>
                          Sell - {trade.tsStopLossOrder.data.orderType}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Stop Loss Price</b>
                          <br/>
                          ${trade.tsStopLossOrder.data.stopPrice}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Status</b>
                          <br/>
                          {trade.tsStopLossOrder.data.status},  {trade.tsStopLossOrder.data.statusDescription}
                        </div>
                        <div className="col-12 col-sm-3">
                          <b>Duration</b>
                          <br/>
                          {trade.tsStopLossOrder.data.duration},   {trade.tsStopLossOrder.data.goodTillDate}
                        </div>
                
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>

            </div>
          </div>
          <hr/>
          <div className='row mt-3'>
            <div className="col-12 col-sm-12">
              <small className='text-primary'>You are allowed to set the Sell Limit Prices one time.</small>
              <div className='row'>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <label className="form-label">Sell Limit Price</label>
                    <input type="number" 
                    name="sellLimitPrice"
                    onChange={(e)=>changeLimitPrices(e)}
                    value={limitPrices.sellLimitPrice}
                    disabled={trade.sellLimitPricesSetCount>0}
                    className="form-control" />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <label className="form-label">Sell Stop Loss Price</label>
                    <input type="number" 
                    name="stopLossPrice"
                    onChange={(e)=>changeLimitPrices(e)}
                    disabled={trade.sellLimitPricesSetCount>0}
                    value={limitPrices.stopLossPrice}
                    className="form-control" />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-12 col-sm-4">
                  <div className="mb-3">
                    <button 
                      onClick={()=>updateSellLimitPrices()}
                      disabled={trade.sellLimitPricesSetCount>0}
                      type="button" className="btn btn-outline-primary">Update Sell Limit Prices</button>
                      {
                        trade.sellLimitPricesSetCount>0 && 
                        <div><small>You have already set the Sell Limit Prices</small></div>
                      }
                  </div>
                  
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default ActiveTrade;
