// import axios from 'axios';
import GraphQLClient from './GraphQLConfig';
import { gql } from '@apollo/client';

// var instance = axios.create({
//     baseURL: process.env.REACT_APP_GRAPHQL_URL
// });

// var bearerToken = process.env.REACT_APP_API_TOKEN

const OffHourMoveGraphQL = {

    getTodayOffHourMoves: (date) => {

      const getBigMoves = gql`
        query getTodayOffHourMoves($date: timestamp!) {
          off_hour_moves(where: {end_date_time: {_gte: $date}}) {
            id
            highLowDiff: high_low_diff
            endPrice: end_price
            endDateTime: end_date_time
            priceDiff: price_diff
            security_id
            startDateTime: start_date_time
            startPrice: start_price
            ticker
          }
        }
      `;


      return GraphQLClient
      .query({
        query: getBigMoves,
        variables: {
          date: date
        },
        fetchPolicy: "no-cache"
      });
    },
    getHistoricalOffHourMoves: (limit) => {

      const getHistoricalBigMoves = gql`
        query getHistoricalOffHourMoves($limit: Int!) {
          off_hour_moves(order_by: {id: desc}, limit: $limit) {
            id
            high_low_diff
            end_price
            end_date_time
            price_diff
            security_id
            start_date_time
            start_price
            ticker
          }
        }
      `;


      return GraphQLClient
      .query({
        query: getHistoricalBigMoves,
        variables: {
          limit: limit
        },
        fetchPolicy: "no-cache"
      });
    },
   
}

export default OffHourMoveGraphQL;