import React from 'react';
import { useState , useEffect} from "react";
import {
  useNavigate
} from "react-router-dom";
import SecurityApi from '../api/SecurityApi';
import { TradeAssetType } from '../components/enum/OrderEnums';

function SecurityCreatePage() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState({msg:"", debugMessage:""});

  const [stockDetails, setStockDetails] = useState({name:"", ticker:"", rating:0.0, nextEarningReportDate:"", type:"STOCK", dailyChangeRate: 0.0, hourlyChangeRate: 0.0, description:"", tags:[]});

  // console.log("tickerActiveTrade, ", tickerActiveTrade)


  useEffect(() => {

    console.log("SecurityCreatePage")


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleInputChange = (e) => {
    setStockDetails(stockDetails => ({
      ...stockDetails,
      [e.target.name]: e.target.value,
    }))
  }

  const save = () => {
    let security = JSON.parse(JSON.stringify(stockDetails))

    console.log("save, ", security)

    SecurityApi.create(security).then((response) => {
      console.log("security update response: ", response.data);  

      let security = response.data

      if(security.type===TradeAssetType.STOCK){
        navigate("/stock?ticker="+security.ticker)
      }else if(security.type===TradeAssetType.CRYPTO){
        navigate("/crypto?ticker="+security.ticker)
      }

    }).catch((error) => {
      console.error("Error msg: ", error.message);
      console.error("Error: ", error);
      if(error.response.data){
        setErrorMsg({
          msg:error.response.data.message,
          debugMessage:error.response.data.debugMessage})
      }else{
        setErrorMsg(error.message+". Server may be down")
      }
    });

  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>

          <div className='row'>
            <div className="col-12 col-sm-12">

              <div className='row'>
                  <div className="col-12 col-sm-12">
                    <h5>Create</h5>
                  </div>
              </div>

              <div className='row'>
                <div className="col-12 col-md-12">
                  {
                    errorMsg.msg && 
                    <div className="alert alert-danger">
                      {errorMsg.msg}
                      <br/>
                      {errorMsg.debugMessage}
                    </div>
                  }
                </div>
              </div>

              <div className='row mt-2'>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Ticker</label>
                    <input 
                      name="ticker"
                      value={stockDetails.ticker || ""}
                      onChange={handleInputChange}
                      className="form-control" />
                  </div>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Name</label>
                    <input 
                      name="name"
                      value={stockDetails.name || ''}
                      onChange={handleInputChange}
                      className="form-control" />
                  </div>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Earning Report</label>
                    <input 
                      type='datetime-local'
                      name="nextEarningReportDate"
                      value={stockDetails.nextEarningReportDate || ''}
                      onChange={handleInputChange}
                      className="form-control" />
                  </div>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Type</label>
                    <select 
                      className="form-select" 
                      name='type'
                      onChange={handleInputChange}
                      value={stockDetails.type}>
                      <option value="STOCK">STOCK</option>
                      <option value="CRYPTO">CRYPTO</option>
                    </select>
                  </div>
              </div>
              <div className='row mt-2'>
                  <div className="col-12 col-sm-6">
                    <label className="form-label">Description</label>
                    <textarea 
                      onChange={handleInputChange}
                      name='description'
                      value={stockDetails.description || ''}
                      className="form-control" rows="3">

                      

                    </textarea>
                  </div>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Daily Change Rate</label>
                    <input 
                      name="dailyChangeRate"
                      value={stockDetails.dailyChangeRate || ''}
                      onChange={handleInputChange}
                      className="form-control" />
                  </div>
                  <div className="col-12 col-sm-3">
                    <label className="form-label">Hourly Change Rate</label>
                    <input 
                      name="hourlyChangeRate"
                      value={stockDetails.hourlyChangeRate || ''}
                      onChange={handleInputChange}
                      className="form-control" />
                  </div>
              </div>
              <div className='row mt-2'>
                  <div className="col-12 col-sm-6">
                    <label className="form-label">Rating</label>
                    <select 
                      className="form-select" 
                      name='rating'
                      onChange={handleInputChange}
                      value={stockDetails.rating}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6">
                  <label className="form-label">Tag(s)</label>
                  <br/>
                  {
                    stockDetails.tags.map((tag)=>(
                      <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tag}>{tag}</span>
                    ))
                  }

                  </div>
              </div>
              <div className='row mt-4'>
                  <div className="col-12 col-sm-6">
                    <button 
                    
                    className="btn btn-outline-primary" 
                    onClick={()=>save()}
                    type="button">Save</button>

                    <button 
                      className="btn btn-outline-danger ms-2" 
                      onClick={()=>navigate(-1)}
                      type="button">Cancel</button>
                      
                  </div>
              </div>

            </div>
          </div>

          <br/>

        </div>
      </div>
    </>
  );
}

export default SecurityCreatePage;
