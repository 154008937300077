import React from "react";
import { useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Auth from "../components/Auth";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
function MainRoutes() {

  useEffect(() => {
    console.log("isAuthenticated ", Auth.isAuthenticated())
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 pt-3 ps-5 pe-5">
          <Router>
            {
              Auth.isAuthenticated() 
              
              ? 
              
              <ProtectedRoutes />

              : 
              
              <PublicRoutes />
            }
          </Router>
        </div>
      </div>
    </>
  );
}

export default MainRoutes;