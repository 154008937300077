
export const FileUploadSlug = {
    PERSONAL_NOTE : 'PERSONAL_NOTE',
    TRADE_NOTE : 'TRADE_NOTE'
}

export const FileType = {
    IMAGE : 'IMAGE',
    VIDEO : 'VIDEO',
    PDF: 'PDF',
    UNSUPPORTED: 'UNSUPPORTED'
}