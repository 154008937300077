import './Journal.css';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import NoteApi from '../api/NoteApi';
import {
  useNavigate
} from "react-router-dom";
import FileApi from '../api/FileApi';
import { FileUploadSlug, FileType } from '../components/enum/FileSlugs';
import DateTimeUtil from '../utility/DateTimeUtil';
import LoadingSpinner from '../components/Spinner';

function JournalAddUpdate() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  const [showBusy, setShowBusy] = useState(false);

  const [entry, setEntry] = useState({content:"", date:DateTimeUtil.getTodayDate(),type:"JOURNAL",files:[]});

  const [searchParams,] = useSearchParams();

  // Get a specific query parameter
  const uuid = searchParams.get('uuid');


  useEffect(() => {

    // loadPresignedUrlToUploadFiles()

    console.log(DateTimeUtil.getTodayDate())

    if(uuid){
      loadNote(uuid)
    }else{
      // populate today's date
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEntryChange = (e) => {
    setEntry({
      ...entry,
      [e.target.name]: e.target.value,
    });

    setDisableSaveBtn(false)
  };

  const addUpdateNote = () => {
    console.log("addUpdateNote")
    console.log(JSON.stringify(entry))

    setShowBusy(true)

    NoteApi.addUpdate(entry).then((response) => {
      console.log("journal add entry response: ", response.data);

      navigate("/journal/view?uuid="+response.data.uuid)
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
      setShowBusy(true)
    });

  }

  const loadNote = (uuid) => {
    console.log("loadNote uuid=", uuid)

    NoteApi.getJournalNoteByUuid(uuid).then((response) => {
      console.log("journal add entry response: ", response.data);
      setEntry(response.data)
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  // const loadPresignedUrlToUploadFiles = () => {
  //   console.log("loadPresignedUrlToUploadFiles")

  //   FileApi.getPresignedUrlToUploadFile("PERSONAL_NOTE").then((response) => {
  //     console.log("presignedUrl response: ", response.data);

  //   }).catch((error) => {
  //     console.error("Error: ", error);
  //     setErrorMsg(error.message)
  //     console.error("Error: ", errorMsg);
  //   });
  // }

  const handleFileLoads = (e) => {

    setDisableSaveBtn(false)

    console.log("handleFileLoads")
    let files = e.target.files

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      console.log(file.name)

      formData.append('files', file);
    }

    // setSelectedFiles(files);

    if(files.length>0){

      setDisableSaveBtn(true)

      setShowBusy(true)

      FileApi.uploadFiles(FileUploadSlug.PERSONAL_NOTE, formData).then((response) => {
        console.log("files uploaded response: ", response.data);

        let newEntry = JSON.parse(JSON.stringify(entry));

        newEntry['files'] = newEntry.files.concat(response.data)

  
        setEntry(newEntry);

        setDisableSaveBtn(false)
        setShowBusy(false)
        
      }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);

        setDisableSaveBtn(false)
        setShowBusy(false)
      });

    }
  };

  const removeFile = (fileId) => {

    console.log("removeFile id=", fileId)

    let newEntry = JSON.parse(JSON.stringify(entry));

    console.log("files ", newEntry['files'])

    newEntry['files'] = newEntry['files'].filter(file => file.id !== fileId);

    console.log("newFiles ", newEntry['files'])


    console.log("newEntry ", newEntry)

    setEntry(newEntry)

    setDisableSaveBtn(false)

  }

  const cancel = () => {
    if(uuid){
      navigate("/journal/view?uuid="+uuid)
    }else{
      navigate("/journal")
    }
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <h4>Journal Entry</h4>
            </div>
            <div className='col-12 col-sm-6'>
              <div className='row'>
                <div className='col-12 col-sm-6'>
                  <button 
                  onClick={()=>addUpdateNote()}
                  disabled={disableSaveBtn}
                  type="button" className="btn btn-outline-primary">Save</button>

                  <button 
                  onClick={()=>cancel()}
                  type="button" 
                  className="btn btn-outline-danger ms-2">Cancel</button>


                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <LoadingSpinner show={showBusy} />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className="mb-3">
                <label className="form-label"><b>Date</b></label>
                <input 
                  name="date"
                  onChange={handleEntryChange}
                  value={entry.date} 
                  type="date" 
                  className="form-control"/>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className="mb-3">
                <label className="form-label"><b>Content</b></label>
                <textarea 
                  name="content"
                  onChange={handleEntryChange}
                  value={entry.content} 
                className="form-control" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className="mb-3">
                <label className="form-label">Media</label>
                <input 
                  onChange={handleFileLoads} 
                  className="form-control" type="file" multiple />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='row'>
                {
                  entry.files.length > 0 &&
                  entry.files.map((file)=>(
                    
                      <div key={file.id} className='col-12 col-sm-4 text-center'>

                        <div className='row'>
                          <div className='col-12'>
                            {file.fileName}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 border'>
                            {
                              file.fileUIType === FileType.IMAGE &&
                              <img src={file.s3GeneratedUrl} className="img-fluid" alt="..."/>
                            }
                            {
                              file.fileUIType === FileType.VIDEO &&
                              <video width="500" height="350" controls>
                                 <source src={file.s3GeneratedUrl} type={file.contentType}></source>
                                 Your browser does not support the video tag.
                              </video>
                            }
                            {
                              file.fileUIType === FileType.PDF &&
                              <iframe 
                              src={file.s3GeneratedUrl} 
                              width="500" 
                              height="350" 
                              title={file.fileName}>
                              </iframe>
                            }
                          </div>
                        </div>

                        <div className='row mb-1'>
                          <div className='col-12'>
                            <button 
                              onClick={(e)=>removeFile(file.id)}
                              type="button" className="btn btn-outline-danger btn-sm">remove</button>
                          </div>
                        </div>

                      </div>
                    
                  ))
                }
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default JournalAddUpdate;
