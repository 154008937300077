import axios from 'axios';
import Auth from '../components/Auth';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

var user = Auth.getAuth()

const MarketDataApi = {

    getRSI: (ticker) => {

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'token': user.token
            }
        };
        return instance.get('/marketdata/get-rsi?ticker='+ticker, options);
    }
}

export default MarketDataApi;