import React from 'react';
import { useEffect } from "react";
import YouTube from 'react-youtube';


const videos = [
  {
    youTubeId: "loekIU7tghQ"
  }
]

const resources = [
  {
    title: "Option Trading IQ",
    url: "https://optionstradingiq.com/option-education/"
  }
]

function OptionTradingStrategyPage() {

  console.log("OptionTradingStrategyPage")

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Option Trading</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            Earnings trading refers to buying and selling stocks based on quarterly earnings reports. 
            These reports can cause significant volatility in stock prices, providing traders with opportunities for quick profits. 
            The strategy can be implemented before, during, or after the earnings release.
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>When to Use</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Before Earnings</b>: If you have a strong view on the likely outcome of the earnings, you can take a position leading up to the announcement.
                    </li>
                    <li>
                      <b>After Earnings</b>: If you prefer to avoid the immediate volatility, you can trade on the subsequent price trend.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>How to do</h5>
                </div>
              </div>
              <ol>
                <li>2 days before: check the news or updates from the company.</li>
                <li>1 day before: check the prize before the day of earning report. Sometimes insides leak news that after the share price leading up the earning report day.</li>
                <li>Day of: check earning reports to see if they beat expectations.</li>
                <li>Day of: use technical analysis to feel out the market. By now you should have a good idea where the market is going based on your research.</li>
                <li>Day of: the market can be volatile(up big or down big or nothing) as the reports are being released.</li>
                <li>End "Day of" or next day: the market can pull/bounce back after a huge jump/fall.</li>
              </ol>

            </div>
          </div>
              
          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>Things to Consider</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                    <b>Research</b>: Look at historical earnings, revenue trends, and analysts' expectations.
                    </li>
                    <li>
                      <b>Volatility</b>: Use options data to gauge expected price volatility.
                    </li>
                    <li>
                      <b>Entry Point Pre-Announcement</b>: Enter based on expected price movement due to earnings speculation.
                    </li>
                    <li>
                      <b>Entry Point Post-Announcement</b>: Wait for the announcement and enter based on the market's reaction.
                    </li>
                    <li>
                      <b>Exit Strategy</b>: Use options data to gauge expected price volatility.
                    </li>
                    <li>
                      <b>Risk Management</b>: Use stop-loss orders or options strategies like protective puts to manage downside risk.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default OptionTradingStrategyPage;
