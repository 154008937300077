import './GapUpOrDown.css';
import React from 'react';
import { useState , useEffect} from "react";
import WatchListApi from '../api/WatchListApi';
import WatchListItem from '../components/WatchListItem';
import MyTradingStrategy from '../components/MyTradingStrategy';
import Auth from '../components/Auth';

const newsPlatforms = [
  {
    title: "MarketWatch",
    url: "https://www.marketwatch.com/"
  },
  {
    title: "Yahoo",
    url: "https://finance.yahoo.com"
  },
  {
    title: "Google",
    url: "https://www.google.com/finance/"
  },
  {
    title: "Robinhood",
    url: "https://robinhood.com"
  },
  {
    title: "Stocktwits",
    url: "https://stocktwits.com"
  },
  {
    title: "Stocknews",
    url: "https://stocknews.com/"
  },
  {
    title: "Trade Ideas",
    url: "https://www.trade-ideas.com/"
  },
  {
    title: "Tipranks",
    url: "https://www.tipranks.com/"
  },
  {
    title: "Benzinga",
    url: "https://www.benzinga.com/"
  }
]

function Dashboard() {

  const [securities, setSecurities] = useState([{id:0}]);

  const [watchList, setWatchList] = useState({id:0,securities:[{id:0}]});

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {

    if(!Auth.isAuthenticated()){
      window.location.href = '/signin'
    }

    WatchListApi.getByTitle("Top 10").then((response) => {
      console.log("response: ", response);
      
      setWatchList(response.data)

      setSecurities(response.data.securities)
      
    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.log(errorMsg)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-sm-7'>
          <MyTradingStrategy />
        </div>
        <div className='col-12 col-sm-5'>
          <h3>News Platforms</h3>
          <div className='row'>
              
            {
              newsPlatforms.map((newsPlatform)=>(
                <div className='col-2 col-sm-2' key={newsPlatform.url}>
                  <a href={newsPlatform.url}
                    rel="noreferrer"
                    target="_blank"
                    className="link-underline-primary">
                      {newsPlatform.title}</a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className='row mt-1 mb-4'>
        <div className='col-12'>


        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h3>WatchList: {watchList.title}</h3>
          <div className="col-12 col-md-12">
            <div className="row">
              { securities.length > 0 &&
                securities.map((security)=>(
                  <WatchListItem key={security.id} ticker={security.ticker} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
