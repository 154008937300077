import React from 'react';

function ApiError({ axiosApiError }) {

  console.log("axiosApiError ",axiosApiError)

  return (
    <>
      {
        axiosApiError.response?.data?.message && 
        <div className='row alert alert-danger'>
          <div className="col-12 col-sm-12">

            <div className='row'>
              <div className="col-12 col-sm-12">

                {axiosApiError.response.data.message}
                
              </div>
            </div>

            <div className='row'>
              <div className="col-12 col-sm-12">

                {
                  axiosApiError.response.data.errors && axiosApiError.response.data.errors.length>0 &&
                  axiosApiError.response.data.errors.map((error, i)=>(
                    <div className='row' key={i}>
                      <div className="col-12 col-sm-12">

                        {error}
                        
                      </div>
                    </div>
                  ))
                }
                
              </div>
            </div>
            
          </div>
        </div>
      }
    </>
  );
}

export default ApiError;
