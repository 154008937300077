
import React from 'react';
import { useEffect} from "react";
import { setUser, setDisabledBuy } from '../store/UserReducer';
import { useSelector, useDispatch } from 'react-redux'
import WebsocketConnector from './WebsocketConnector';
import Auth from "../components/Auth";
import TickerSearch from './TickerSearch';

function AccountDetails() {

  // const disabledBuy = useSelector((state) => state.userInfo.disabledBuy)

  const userInfo = useSelector((state) => state.userInfo.userInfo)

  const stompClient = WebsocketConnector.connect()

  const dispatch = useDispatch()

  var auth = Auth.getAuth()

  useEffect(() => {

    console.log("userInfo ", JSON.stringify(userInfo))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {



    stompClient.onConnect = (frame) => {
      console.log('account details websocket Connected:', frame);

      stompClient.subscribe('/topic/user-detail/'+auth.uuid, (message) => {
        let account = JSON.parse(message.body)
        console.log("account detail data", account)

        dispatch(setUser(account));

        dispatch(setDisabledBuy(account.disabledBuy));

      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      <ul className="nav">
        <li className='me-3 border p-1 rounded'>
          Balance
          :
          ${userInfo.balance}
        </li>
        <li className='me-2 border p-1 rounded'>
          # of Trades : {userInfo.numberOfActiveTrades} / {auth.numberOfTradeLimit}
        </li>
        <li>
          <TickerSearch />
        </li>
      </ul>

    </>
  );
}

export default AccountDetails;
