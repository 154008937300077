import './WatchListItem.css';
import React from 'react';
import { useState , useEffect} from "react";
import {
  useNavigate
} from "react-router-dom";
import MarketDataApi from '../api/MarketDataApi';

function WatchListItem({ ticker }) {


  const navigate = useNavigate();

  // load data and indicators
  // over bought and over sold
  // indicate down or up daily
  // vwap of 45 minutes
  // change in volume
  const [rsiIndicator, setRsiIndicator] = useState({ticker:"", price:0.0, status:""});

  useEffect(() => {
    fetchRSI()

    // Set the interval to make the API call every 30 seconds
    const intervalId = setInterval(fetchRSI, 30000);

        // Cleanup: Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRSI  = () => {
    MarketDataApi.getRSI(ticker).then((response) => {
      console.log("response: ", response);

      setRsiIndicator(response.data)
      
    }).catch((error) => {
      console.error("Error: ", error);
    });
  }

  const goTo  = (ticker) => {
    navigate("/stock?ticker="+ticker)
  }

  const getStatusBtn = (status) => {

    if(status==='Over Bought'){
      return (
        <button type="button" className="btn btn-outline-danger">
          {status}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
            <path d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
          </svg>
        </button>
        )
    }
    
    if(status==='Over Buying'){
      return (
        <button type="button" className="btn btn-outline-warning">
          {status}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
            <path d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
          </svg>
        </button>
      )
    }

    if(status==='Over Sold'){
      return (
        <button type="button" className="btn btn-outline-danger">
          {status}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
            <path d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
          </svg>
        </button>
        )
    }


    if(status==='Over Selling'){
      return (
        <button type="button" className="btn btn-outline-warning">
          {status}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
            <path d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
          </svg>
        </button>
      )
    }



    return status
  }

  return (
      <div className="col-12 col-sm-3 p-2">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <button 
                onClick={()=>goTo(ticker)}
                type="button" className="btn btn-link">{ticker}</button>

              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <h4>RSI</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        Status
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {getStatusBtn(rsiIndicator.status)}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row">
                      <div className="col-12">
                        RSI
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                      {rsiIndicator.rsi}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row">
                      <div className="col-12">
                        Price
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        ${rsiIndicator.price}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default WatchListItem;
