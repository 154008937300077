import React from 'react';
import {
  useNavigate
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';



function IndicatorSidebar() {

  const pathUrl = window.location.pathname

  console.log('pathUrl, ', pathUrl)

  const navs = [
    {
      name: 'VWAP',
      urlSlug : 'vwap',
      highlight: pathUrl==="/indicators/vwap"
    },
    {
      name: 'RSI',
      urlSlug : 'rsi',
      highlight: pathUrl==="/indicators/rsi"
    },
    {
      name: 'MACD',
      urlSlug : 'macd',
      highlight: pathUrl==="/indicators/macd"
    },
    {
      name: 'EMA',
      urlSlug : 'ema',
      highlight: pathUrl==="/indicators/ema"
    },
    {
      name: 'Volume',
      urlSlug : 'volume',
      highlight: pathUrl==="/indicators/volume"
    }
  ]

  const navigate = useNavigate();

  return (
    <Nav variant="pills" className="flex-column">

      {
        navs.map((item , index)=>(
          <Nav.Link key={item.name}
            className={`border ${index === navs.length - 1 ? 'border-bottom' : 'border-bottom-0'} ${item.highlight ? 'active' : ''}`}
            onClick={()=>navigate("/indicators/"+item.urlSlug+"?sidebar=indicator")}
          >
            {item.name}
          </Nav.Link>
        ))
      }
      
    </Nav>
  );
}

export default IndicatorSidebar;
