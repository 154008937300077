import { Outlet } from 'react-router-dom';


function IndicatorOutlet() {

  return (
    <>
      <Outlet/> 
    </>
  );
}

export default IndicatorOutlet;
