import Auth from '../components/Auth';

import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

var user = Auth.getAuth()

var apiUrl = process.env.REACT_APP_API_URL

const WebsocketConnector = {

    connect: () => {
        return new Client({
            webSocketFactory: () => new SockJS(apiUrl+'/v1/trade-websocket?token='+user.token),
        });
    }
}

export default WebsocketConnector;