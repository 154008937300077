import axios from 'axios';

var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/v1"
});

const BackendApi = {

    pingApiServer: () => {

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return instance.get('/ping', options);
    }
}

export default BackendApi;