import './BuyOptions.css';
import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
// import Auth from '../components/Auth';
import StreamerApi from '../api/StreamerApi';
import WebsocketConnector from './WebsocketConnector';
import BuyOptionQuotes from './BuyOptionQuotes';

function BuyOptions({ currentPriceBar , buyEnabled}) {

  const stompClientOptionChains = WebsocketConnector.connect()

  const [searchParams, ] = useSearchParams();

  const ticker = searchParams.get('ticker');

  const [optionChains, setOptionChains] = useState({})

  const [strikePrices, setStrikePrices] = useState([])

  const [expirationDate, setExpirationDate] = useState('')

  const [selectedQuote, setSelectedQuote] = useState({'symbol':'','tradeDir':'', 'strikePrice':0})


  // const [callPrices, setCallPrices] = useState({});

  // const [putPrices, setPutPrices] = useState({});

  // // Get a specific query parameter
  // const ticker = searchParams.get('ticker');

  // const [errorMsg, setErrorMsg] = useState("");

      
  useEffect(() => {

    StreamerApi.streamOptionChains(ticker).then((response) => {
      console.log("start option chain response: ", response);

      setExpirationDate(response.data)

    }).catch((error) => {
        console.error("Error: ", error);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    stompClientOptionChains.onConnect = (frame) => {
      console.log('option chains Connected:', frame);

      stompClientOptionChains.subscribe('/topic/option-chain/'+ticker, (message) => {
        let optionChain = JSON.parse(message.body)
        // console.log("got option chain from webhook ", optionChain)

        addOptionChain(optionChain)

      });
    };

    stompClientOptionChains.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClientOptionChains.connected) {
        stompClientOptionChains.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOptionChain  = (optionChain) => {

    let strikePrice = optionChain['Strikes'][0]

    strikePrice = parseFloat(strikePrice)

    let newStrikePrices = strikePrices

    newStrikePrices.push(strikePrice)

    newStrikePrices = [...new Set(newStrikePrices)]

    newStrikePrices = newStrikePrices.sort()

    setStrikePrices(newStrikePrices)

    let newOptionChains =  optionChains ? optionChains : {}

    if(!newOptionChains.hasOwnProperty(strikePrice)){
      newOptionChains[strikePrice] = {}
    }

    newOptionChains[strikePrice][optionChain.Side] = optionChain

    setOptionChains(newOptionChains)


  }

  const order  = (side, strikePrice) => {
    console.log("order ", side, strikePrice)

    let optionChain = optionChains[strikePrice][side]

    let symbol = optionChain['Legs'][0]['Symbol']

    console.log("selected symbol ", symbol)

    setSelectedQuote({'symbol':symbol,'tradeDir':side, 'strikePrice': strikePrice})
  }



  return (
    <>
      <div className='row'>
        <div className="col-12 col-sm-12">
          <div className='row mb-2'>
            <div className="col-12 col-sm-12">
              Expiration Date: {expirationDate}
            </div>
          </div>

          <div className='row mb-2'>
            <div className="col-12 col-sm-12">

              <table className="table table-hover">

              <thead>
                <tr>
                  <th scope="col">Open Int.</th>
                  <th scope="col">Impl Vol.</th>
                  <th scope="col">Vol.</th>
                  <th scope="col">Delta</th>
                  <th scope="col">High</th>
                  <th scope="col">Mid</th>
                  <th scope="col">Low</th>
                  <th scope="col">Ask</th>
                  <th scope="col">Bid</th>
                  <th scope="col">Strike</th>
                  <th scope="col">Bid</th>
                  <th scope="col">Ask</th>
                  <th scope="col">Low</th>
                  <th scope="col">Mid</th>
                  <th scope="col">High</th>
                  <th scope="col">Delta</th>
                  <th scope="col">Vol.</th>
                  <th scope="col">Impl Vol.</th>
                  <th scope="col">Open Int.</th>
                </tr>
              </thead>
              <tbody>
                  {
                    strikePrices.length > 0 &&
                    strikePrices.map((strikePrice, index)=>(
                      <>
                      {
                        index === (strikePrices.length / 2) &&
                        <tr key={Math.random()+index}>
                          <td colSpan="19" className='text-center'>
                            <b>CALL <i className="fas fa-arrow-up"></i> - Share Price ${currentPriceBar.Close} - PUT <i className="fas fa-arrow-down"></i></b>
                          </td>
                        </tr>
                        
                      }
                        
                        <tr key={strikePrice}>

                          

                          {
                            optionChains[strikePrice] && optionChains[strikePrice]['Call'] !== undefined ? (
                              <>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['DailyOpenInterest']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{(parseFloat(optionChains[strikePrice]['Call']['ImpliedVolatility']) * 100).toFixed(2)+"%"}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Volume']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Delta']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['High']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Mid']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Low']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Ask']}</td>
                                <td 
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Call' ? 'table-success' : ''}
                                  onClick={()=>order('Call', strikePrice)}>{optionChains[strikePrice]['Call']['Bid']}</td>
                              </>
                            ) : null
                          }

                          <td className='border table-success'>{strikePrice}</td>

                            {
                              optionChains[strikePrice] && optionChains[strikePrice]['Put'] !== undefined ? (
                              <>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Bid']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Ask']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Low']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Mid']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['High']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Delta']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['Volume']}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{(parseFloat(optionChains[strikePrice]['Put']['ImpliedVolatility']) * 100).toFixed(2)+"%"}</td>
                                <td  
                                  className={selectedQuote.strikePrice === strikePrice && selectedQuote.tradeDir === 'Put' ? 'table-success' : ''}
                                  onClick={()=>order('Put', strikePrice)}>{optionChains[strikePrice]['Put']['DailyOpenInterest']}</td>
                              </>
                              ) : null
                            }
                   
                        </tr>
                        </>
                    ))
                    
                  }
                  

              </tbody>


              </table>

            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-sm-12">
              <div className='row mb-2'>
                <div className="col-12 col-sm-12">
                  <h4>Option quote</h4>
                </div>
              </div>
                  {
                    selectedQuote.symbol && 
                    <BuyOptionQuotes legsSymbol={selectedQuote.symbol} tradeDir={selectedQuote.tradeDir} buyEnabled={buyEnabled} />
                  }
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default BuyOptions;
