import './Journal.css';
import { useState , useEffect} from "react";
import NoteApi from '../api/NoteApi';
import {
  useNavigate
} from "react-router-dom";
import StringUtil from '../utility/StringUtil';
import NoteGraphQL from '../graphql/NoteGraphQL';

function Journal() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [notePage, setNotePage] = useState({content:[], pageNumber: 0, pageSize: 10, firstPage: true, lastPage: false, pagination: { aggregate: { totalCount : 0 , totalPage: 0}}});


  useEffect(() => {
    loadNotes(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNotes = (pageNumber) => {

    let offset = pageNumber * notePage.pageSize

    NoteGraphQL.getJournalNotes(offset, notePage.pageSize).then((response) => {
      console.log("graphal journal notes response: ", response.data);
      let newPage = response.data
      newPage.pageNumber = pageNumber
      newPage.pageSize = notePage.pageSize
      newPage.lastPage = false
      newPage.firstPage = true

      if(newPage.pagination.aggregate.totalCount <= 0){
        newPage.pagination.aggregate.totalPage = 0
        newPage.lastPage = true

      } else if(newPage.pagination.aggregate.totalCount > 0 && newPage.pagination.aggregate.totalCount < notePage.pageSize){
        newPage.pagination.aggregate.totalPage = 1

      } else if(newPage.pagination.aggregate.totalCount > 0 && newPage.pagination.aggregate.totalCount > notePage.pageSize){
        newPage.pagination.aggregate.totalPage = parseInt(newPage.pagination.aggregate.totalCount / notePage.pageSize) + 1

      } else {
        console.log("else")
      }

      if(newPage.pagination.aggregate.totalCount <= (offset+notePage.pageSize)){
        newPage.lastPage = true
      }

      if(pageNumber !== 0){
        newPage.firstPage = false
      }

      console.log("newPage: ", newPage);

      setNotePage(newPage)

    }).catch((error) => {
      console.error("Error: ");
      console.error(error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  const addUpdateNote = (uuid) => {
    let url = "/journal/add-update"
    if(uuid){
      url += "?uuid="+uuid
    }
    navigate(url)
  }

  const viewNoteDetails = (uuid) => {
    let url = "/journal/view"
    if(uuid){
      url += "?uuid="+uuid
    }
    navigate(url)
  }

  const goToDate = (day) => {
    NoteApi.createOrGet(day).then((response) => {
      console.log("createOrGet note response: ", response.data);
      let note = response?.data

      let url = "/journal/add-update"
      
      if(note.uuid){
        url += "?uuid="+note.uuid
      }

      navigate(url)

    }).catch((error) => {
      console.error("Error: ", error);
      setErrorMsg(error.message)
      console.error("Error: ", errorMsg);
    });
  }

  const goToNextPage = () => {
    loadNotes(notePage.pageNumber+1)
  }

  const goToPreviousPage = () => {
    loadNotes(notePage.pageNumber-1)
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Journal</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <button
                onClick={()=>addUpdateNote()}
                type="button" 
                className="btn btn-outline-primary btn-sm me-2">Add</button>
              <button
                onClick={()=>goToDate('yesterday')}
                type="button" 
                className="btn btn-outline-primary btn-sm me-2">Yesterday</button>
              <button
                onClick={()=>goToDate('today')}
                type="button" 
                className="btn btn-outline-primary btn-sm me-2">Today</button>
              <button
                onClick={()=>goToDate('tomorrow')}
                type="button" 
                className="btn btn-outline-primary btn-sm">Tomorrow</button>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Date</th>
                    <th scope="col">Content</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    notePage.content.length > 0 &&
                    notePage.content.map((note) => (
                      <tr key={note.id}>
                        <td>
                          <button 
                            onClick={()=>viewNoteDetails(note.uuid)}
                            type="button" className="btn btn-link">{note.id}</button>
                        </td>
                        <td>
                          <button 
                            onClick={()=>viewNoteDetails(note.uuid)}
                            type="button" className="btn btn-link">{note.dateOf}</button>
                            </td>
                        <td>{StringUtil.truncateString(note.content, 20)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div className='row'>
                <div className='col-4'>
                  <button 
                    type="button" 
                    className="btn btn-outline-primary"
                    disabled={notePage.firstPage}
                    onClick={()=>goToPreviousPage()}
                  ><span aria-hidden="true">&laquo;</span> Previous</button>
                </div>
                <div className='col-4 text-center'>
                  # of total notes {notePage.pagination.aggregate.totalCount}, # of pages {notePage.pagination.aggregate.totalPage}, current page {notePage.pageNumber+1}
                </div>
                <div className='col-4 text-end'>
                  <button 
                    type="button" 
                    className="btn btn-outline-primary"
                    disabled={notePage.lastPage}
                    onClick={()=>goToNextPage()}
                  >Next <span aria-hidden="true">&raquo;</span></button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Journal;
