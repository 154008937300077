import React from "react";
import { useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Dashboard from "../pages/Dashboard";
import Navbar from "../layout/Navbar";
import HeaderPublic from "../layout/HeaderPublic";
import FooterPublic from "../layout/FooterPublic";
import ChartPatternPage from "../pages/chart_patterns/ChartPatternPage";
import DoubleTopChartPatternPage from "../pages/chart_patterns/DoubleTopChartPatternPage";
import DoubleBottomChartPatternPage from "../pages/chart_patterns/DoubleBottomChartPatternPage";
import ChartPatternOutlet from "../pages/chart_patterns/ChartPatternOutlet";
import StrategyOutlet from "../pages/strategies/StrategyOutlet";
import StrategyPage from "../pages/strategies/StrategyPage";
import EarningStrategyPage from "../pages/strategies/EarningStrategy";
import GapUpDownStrategyPage from "../pages/strategies/GapUpDownStrategy";
import MomentumStrategyPage from "../pages/strategies/MomentumStrategy";
import IndicatorPage from "../pages/indicators/IndicatorPage";
import IndicatorOutlet from "../pages/indicators/IndicatorOutlet";
import VwapPage from "../pages/indicators/VwapPage";
import RsiPage from "../pages/indicators/RsiPage";
import EmaIndicatorPage from "../pages/indicators/EmaIndicatorPage";
import MacdPage from "../pages/indicators/MacdPage";
import PriceActionStrategyPage from "../pages/strategies/PriceActionStrategy";
import HeadAndShouldersChartPatternPage from "../pages/chart_patterns/HeadAndShouldersChartPatternPage";
import FlagChartPatternPage from "../pages/chart_patterns/FlagChartPatternPage";
import WedgeChartPatternPage from "../pages/chart_patterns/WedgeChartPatternPage";
import VolumeIndicatorPage from "../pages/indicators/VolumeIndicatorPage";
import OptionTradingStrategyPage from "../pages/strategies/OptionTradingStrategy";

function PublicRoutes() {

  // Get the current URL
  let currentUrl = window.location.href;

  // Create a URL object from the current URL
  let url = new URL(currentUrl);

  // Split the pathname to get segments
  let pathSegments = url.pathname.split('/');

  // Access the first path segment. Note: The first element is empty if the path starts with '/'
  let firstPathSegment = pathSegments[1];

  useEffect(() => {
  }, []);

  const getAllRoutes = () => {
    return  <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="signin" element={<SignIn />} />  
      <Route path="chart-patterns" element={<ChartPatternOutlet />}>
        <Route index element={<ChartPatternPage />} />
        <Route path="double-top" element={<DoubleTopChartPatternPage />} />
        <Route path="double-bottom" element={<DoubleBottomChartPatternPage />} />
        <Route path="head-and-shoulders" element={<HeadAndShouldersChartPatternPage />} />
        <Route path="flag" element={<FlagChartPatternPage />} />
        <Route path="wedge" element={<WedgeChartPatternPage />} />
        <Route path="*" element={<p>Page not found: 404!</p>} />
      </Route>

      <Route path="strategies" element={<StrategyOutlet />}>
        <Route index element={<StrategyPage />} />
        <Route path="price-action" element={<PriceActionStrategyPage />} />
        <Route path="earning" element={<EarningStrategyPage />} />
        <Route path="gap-up-down" element={<GapUpDownStrategyPage />} />
        <Route path="momentum" element={<MomentumStrategyPage />} />
        <Route path="options" element={<OptionTradingStrategyPage />} />
        <Route path="*" element={<p>Page not found: 404!</p>} />
      </Route>

      <Route path="indicators" element={<IndicatorOutlet />}>
        <Route index element={<IndicatorPage />} />
        <Route path="vwap" element={<VwapPage />} />
        <Route path="rsi" element={<RsiPage />} />
        <Route path="ema" element={<EmaIndicatorPage />} />
        <Route path="macd" element={<MacdPage />} />
        <Route path="volume" element={<VolumeIndicatorPage />} />
      </Route>
    </Routes>
  }

  const getRoutes = (signIn) => {
    if(signIn){
      return  <div className="col-12">
        {getAllRoutes()}
      </div>
    }else{
      return  <>
        <div className="col-2">
          <Navbar/>
        </div>
        <div className="col-10">
          {getAllRoutes()}
        </div>
      </>
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 pt-3 ps-5 pe-5">
          <div className="row">
            <div className="col-12">
              <HeaderPublic />
            </div>
          </div>

          <div className="row">
              {
                getRoutes(firstPathSegment==='signin')
              }
           
          </div>

          <div className="row">
            <div className="col-12">
              <FooterPublic />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicRoutes;