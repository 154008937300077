import React from 'react';
import { useState , useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import StreamerApi from '../api/StreamerApi';
import BuySecurities from '../components/BuyStocks';
import WebsocketConnector from '../components/WebsocketConnector';
import ActiveTrade from '../components/ActiveTrade';
import TradeHistoryMinDetail from '../components/TradeHistoryMinDetail';
import {
  useNavigate
} from "react-router-dom";
import { TradeAssetType } from '../components/enum/OrderEnums';
import SecurityGraphQL from '../graphql/SecurityGraphQL';
import TradeGraphQL from '../graphql/TradeGraphQL';

function StockPage() {

  const stompClient = WebsocketConnector.connect()

  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();

  // const [rsiIndicator, setRsiIndicator] = useState({ticker:"", price:0.0, status:""});

  const [currentPriceBar, setCurrentPriceBar] = useState({Close:0.0});

  // const [historicalTrades, setHistoricalTrades] = useState({content:[], first: false, last: false, pageNumber: 0, pageSize: 25, totalPages: 0});
  const [historicalTrades, setHistoricalTrades] = useState([]);

  // Get a specific query parameter
  const ticker = searchParams.get('ticker');

  // const [security, setSecurity] = useState({id:0,ticker:""});

  const [trends, setTrends] = useState([{id:0,ticker:""}]);

  const [errorMsg, setErrorMsg] = useState("");

  const [stockDetails, setStockDetails] = useState({tags:[]});

  const [tickerActiveTrade, setTickerActiveTrade] = useState({});

  // console.log("tickerActiveTrade, ", tickerActiveTrade)


  useEffect(() => {

    console.log("StockPage")

    TradeGraphQL.subscribeToTickerActiveTrade(ticker, TradeAssetType.STOCK)
    .subscribe({
      next(result) {

        let activeTr = result?.data?.trades?.[0]

        console.log("activeTrade: ", activeTr);

        setTickerActiveTrade(activeTr)
      },
      error(err) { console.error('subscriber to active trade err', err); },
    });

    SecurityGraphQL.getSecurityDetails(ticker).then((response) => {
      // console.log("stock details graphql response: ", response.data);  
      setStockDetails(response?.data?.securities?.[0])
    }).catch((error) => {
        console.error("Get security details error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });

    //remove this
    setTrends([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  useEffect(() => {
    
    StreamerApi.start(ticker).then((response) => {
        // console.log("response: ", response);  
    }).catch((error) => {
        console.error("start stream api Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });


    loadHistoricalTrades()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  const loadHistoricalTrades = () => {

    // console.log("loading historical trades data");

    TradeGraphQL.getHistoricalTradesByTicker(ticker, TradeAssetType.STOCK).then((response) => {
      // console.log("historical trades data: ", response);  

      let histTrades = response?.data?.trades || []
      setHistoricalTrades(histTrades)

    }).catch((error) => {
        console.error("getHistoricalTradesByTicker error: ", error);
    });
  }

  const reloadHistoricalTrades = () => {
    console.log("reloading historical trades data");  
    loadHistoricalTrades()
  }

  useEffect(() => {
    
    stompClient.onConnect = (frame) => {
      // console.log('Connected:', frame);

      stompClient.subscribe('/topic/security-current-price/'+ticker, (message) => {
        // console.log("got bar data from webhook")
        updateOnCurrentPrice(JSON.parse(message.body))
      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  const updateOnCurrentPrice  = (currentPrice) => {
    setCurrentPriceBar(currentPrice)
  }

  useEffect(() => {
    

    // TrendApi.getLatest(ticker).then((response) => {
    //   console.log("response: ", response);

    //   setTrends(response.data)
      
    // }).catch((error) => {
    //   console.error("Error: ", error);
    //   setErrorMsg(error.message)
    // });

    // fetchRSI()

    // // Set the interval to make the API call every 30 seconds
    // const intervalId = setInterval(fetchRSI, 30000)

    // // Cleanup: Clear the interval when the component is unmounted
    // return () => clearInterval(intervalId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchRSI  = () => {
  //   MarketDataApi.getRSI(ticker).then((response) => {
  //     console.log("response: ", response);

  //     setRsiIndicator(response.data)
      
  //   }).catch((error) => {
  //     console.error("Error: ", error);
  //   });
  // }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className="col-12 col-sm-12">
              

              <div className='row mb-4'>
                <div className="col-12 col-sm-4">
                  

                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <h4>{stockDetails?.name}</h4>
                      <h6>({stockDetails?.ticker}) <small><button className="btn btn-link" onClick={()=>navigate("/security-update?ticker="+ticker)}>edit</button></small></h6>

                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-sm-12">

                      <div className="btn-group bt-sm">
                        {
                          stockDetails?.type===TradeAssetType.STOCK &&
                          <button 
                            onClick={()=>navigate("/option?ticker="+ticker)}
                            type="button" 
                            className="btn btn-outline-primary bt-sm">Options</button>
                        }

                        <a 
                          target='_blank' 
                          href={process.env.REACT_APP_YAHOO_URL+'/'+ticker}
                          rel="noopener noreferrer"
                          type="button" 
                          className="btn btn-outline-primary bt-sm">Yahoo</a>
                        <a 
                          target='_blank' 
                          href={process.env.REACT_APP_GOOGLE_URL+'/'+ticker+':NASDAQ'}
                          rel="noopener noreferrer"
                          type="button" className="btn btn-outline-primary bt-sm">
                          
                          Google</a>
                        <a 
                          target='_blank'
                          href={process.env.REACT_APP_BROKER_STCK_URL+'/'+ticker}
                          rel="noopener noreferrer"
                          className="btn btn-outline-primary bt-sm">Robinhood</a>

                        
                        

                      </div>

                    </div>
                  </div>
                  
                  <div className='row mt-2'>
                    <div className="col-12 col-sm-12">
                      <b>Earning Report Date</b>
                      <br/>
                      {stockDetails?.earningReportDate}
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-sm-12">
                      <div className='row'>
                        <div className="col-12 col-sm-12">
                          <b>Tags</b>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className="col-12 col-sm-12">
                          {
                            stockDetails && 
                            stockDetails.tags.map((tagObj)=>(
                              <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tagObj.tag}>{tagObj.tag}</span>
                            ))
                          }
                        </div>
                      </div>
                      
                    </div>
                  </div>

                </div>
                <div className="col-12 col-sm-8">

                  <div className='row'>
                    <div className="col-12 col-sm-4">
                      <b>Type</b>
                      <br/>
                      {stockDetails?.type}
                    </div>

                    <div className="col-12 col-sm-4">
                      <b>Daily Change Rate</b>
                      <br/>
                      {stockDetails?.dailyChangeRate}
                    </div>

                    <div className="col-12 col-sm-4">
                      <b>Hourly Change Rate</b>
                      <br/>
                      {stockDetails?.hourlyChangeRate}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-12 col-sm-4">
                      <b>Rating</b>
                      <br/>
                      {stockDetails?.rating}
                    </div>
                    <div className="col-12 col-sm-8">
                      <b>Description</b>
                      <br/>
                      {stockDetails?.description}
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                  
                </div>
              </div>
            </div>
          </div>
          {/* Trends */}
          <div className='row'>
            <div className="col-12 col-md-12">
              {/* <div className='row'>
                <div className="col-12 col-md-12">
                  <h4>Strategy</h4>
                  <MyTradingStrategy />
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-md-12">
                  <h4>RSI</h4>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-md-12">
                  <WatchListItem ticker={ticker} />
                </div>
              </div> */
              }
              <div className='row'>
                <div className="col-12 col-md-12">
                  Price: ${currentPriceBar.Close}
                {/* <button onClick={()=>stopStreaming()} type="button" className="btn btn-outline-primary btn-sm">stop streaming</button> */}
                </div>
              </div>
              <div className='row mb-3'>
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <b>Securities</b>
                    </div>
                    <div className="card-body">
                      <BuySecurities currentPriceBar={currentPriceBar} buyEnabled={tickerActiveTrade} />
                    </div>
                  </div>
                  
                </div>
              </div>
        
              <div className='row mb-2'>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <b>Active Trade</b>
                    </div>
                    <div className="card-body">
                      {
                        tickerActiveTrade && Object.keys(tickerActiveTrade).length>0 &&
                        <ActiveTrade trade={tickerActiveTrade} reloadHistory={reloadHistoricalTrades} />
                      }
                    </div>
                  </div>
                
                </div>
              </div>

              <div className='row mb-2'>
                <div className="col-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <b>Trends</b>
                    </div>
                    <div className="card-body">
                      { trends.length > 0 &&
                        trends.map((trend)=>(
                          <div className="col-12 col-md-3 p-3" key={trend.id}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12">
                                    <a target='_blank' 
                                    rel="noopener noreferrer"
                                    href={process.env.REACT_APP_BROKER_STCK_URL+'/'+trend.ticker}>{trend.ticker}</a>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    Direction: {trend.direction}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    Price Diff: {trend.diff}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <small>Days Ago: {trend.duration}, {trend.startTime} - {trend.endTime}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <b>History</b>
                    </div>
                    <div className="card-body">
                    {
                      historicalTrades.length>0 &&
                      historicalTrades.map(trade => (
                        <div className='row' key={trade.id}>
                          <div className="col-12">
                            <TradeHistoryMinDetail trade={trade} />
                          </div>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StockPage;
