import React from 'react';
import {
  useNavigate
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

function ChartPatternSidebar() {

  const pathUrl = window.location.pathname

  console.log('pathUrl, ', pathUrl)

  const navs = [
    {
      name: 'Double Top',
      urlSlug : 'double-top',
      highlight: pathUrl==="/chart-patterns/double-top"
    },
    {
      name: 'Double Bottom',
      urlSlug : 'double-bottom',
      highlight: pathUrl==="/chart-patterns/double-bottom"
    },
    {
      name: 'Head and Shoulders',
      urlSlug : 'head-and-shoulders',
      highlight: pathUrl==="/chart-patterns/head-and-shoulders"
    },
    {
      name: 'Flag',
      urlSlug : 'flag',
      highlight: pathUrl==="/chart-patterns/flag"
    },
    {
      name: 'Wedge',
      urlSlug : 'wedge',
      highlight: pathUrl==="/chart-patterns/wedge"
    }
  ]

  const navigate = useNavigate();

  return (

      <Nav variant="pills" className="flex-column">

      {
        navs.map((item , index)=>(
          <Nav.Link key={item.name}
            className={`border ${index === navs.length - 1 ? 'border-bottom' : 'border-bottom-0'} ${item.highlight ? 'active' : ''}`}
            onClick={()=>navigate("/chart-patterns/"+item.urlSlug+"?sidebar=chart-pattern")}
          >
            {item.name}
          </Nav.Link>
        ))
      }

      </Nav>
  );
}

export default ChartPatternSidebar;
