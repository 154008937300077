
const DateTimeUtil = {

    getTodayDate: () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      
      const formattedDate = yyyy+'-'+mm + '-' + dd

      return formattedDate
    },
    format: (datetimeStr) => {
      // Parse the ISO date string into a Date object
      const date = new Date("2023-10-16T23:49:33.471424");

      // Extract various date components
      const month = date.getMonth() + 1;  // Month is 0-indexed
      const day = date.getDate();
      const year = date.getFullYear();
      const hours24 = date.getHours();

      // Convert 24-hour time to 12-hour time
      let period = 'AM';
      let hours12 = hours24;
      if (hours24 >= 12) {
        period = 'PM';
        if (hours24 > 12) {
          hours12 = hours24 - 12;
        }
      }

      const minutes = date.getMinutes();

      // Format the components into your desired format
      const formattedDate = `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year} ${hours12 < 10 ? '0' + hours12 : hours12}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
      return formattedDate
    },
    getYesterdayDate: () => {
      const today = new Date();
      today.setDate(today.getDate() - 1); // Sets the day to one day before today
      today.setHours(0, 0, 0, 0); // Optionally reset the time to midnight, if you only want the date part  
      
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      
      const formattedDate = yyyy+'-'+mm + '-' + dd

      return formattedDate
    },

    getDate: (plus) => {
      const today = new Date();
      today.setDate(today.getDate() + plus); // Sets the day to one day before today
      today.setHours(0, 0, 0, 0); // Optionally reset the time to midnight, if you only want the date part  
      
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      
      const formattedDate = yyyy+'-'+mm + '-' + dd

      return formattedDate
    },
    isSameDay: (date1, date2) => {
      // date1 = new Date(date1);
      // date2 = new Date(date2);

      return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
    }
}

export default DateTimeUtil;

