import { createSlice } from '@reduxjs/toolkit'
import Storage from './storage'

const storeName = "activeTrades"

var activeTrades = Storage.getJson(storeName) ? Storage.getJson(storeName) : {}

export const activeTradeSlice = createSlice({
  name: storeName,
  initialState: {
    activeTrades: activeTrades
  },
  reducers: {
    add: (state, action) => {

      let trade = action.payload

      console.log("add new trade ", trade)

      state.activeTrades[trade['ticker']] = trade

      Storage.setJson(storeName, state.activeTrades)
    },
    set: (state, action) => {

      let trades = action.payload

      console.log("add trade list ", trades)

      state.activeTrades = {}

      for (const trade of trades) {
        state.activeTrades[trade['ticker']] = trade
      }

      Storage.setJson(storeName, state.activeTrades)
    },
    remove: (state, action) => {

      let trade = action.payload

      console.log("delete trade ", trade)

      delete state.activeTrades[trade['ticker']]

      Storage.setJson(storeName, state.activeTrades)
    }
  },
})

// Action creators are generated for each case reducer function
export const { set, add, remove} = activeTradeSlice.actions

export default activeTradeSlice.reducer