import React from 'react';
import { useEffect } from "react";
import YouTube from 'react-youtube';


const videos = [
  {
    youTubeId: "My6-KWyOE0g"
  },
  {
    youTubeId: "-ZWLkGZXnK4"
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/trading/introduction-to-momentum-trading/"
  },
  {
    title: "Admiral Markets",
    url: "https://admiralmarkets.com/education/articles/forex-strategy/momentum-trading-strategies"
  },
  {
    title: "Warrior Trading",
    url: "https://www.warriortrading.com/momentum-day-trading-strategy/"
  }
]

function MomentumStrategyPage() {

  console.log("MomentumStrategyPage")

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Momentum Strategy</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
              <h5>Descriptions</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              Momentum trading is a strategy that aims to capitalize on the continuance of existing price trends. 
              The basic idea is to buy assets that have shown strong price gains and sell those that are showing weakness, expecting the momentum to continue.
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              <b>When to use it</b>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <b>Strong Trends</b>: Best used in strongly trending markets.
                <b>Market Open</b>: Often used by day traders in the first few hours of trading when volume and volatility are high.
              </div>
            </div>


            <div className='row'>
              <div className='col-12'>
                <b>Example</b>
                If a stock has been rising steadily and is trading above its 50-day and 200-day moving averages, this indicates a strong upward trend. You might enter a long position after a minor price pullback. If you bought the stock at $100, you might set a stop-loss at $95 and a take-profit at $110.

                Given your experience in day trading and your technical capabilities, including the use of the TradeStation API, you could potentially automate your momentum trading strategy. This could involve coding algorithms to identify strong trends in real-time, automatically entering and exiting positions based on your specified criteria, and dynamically managing risk with stop-loss and take-profit orders.
              </div>
            </div>


   
            </div>
          </div>


          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center' key={video.youTubeId}>
                      <b>{video.title}</b>
                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />
                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default MomentumStrategyPage;
