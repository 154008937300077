// import axios from 'axios';
// import Auth from '../components/Auth';
import GraphQLClient from './GraphQLConfig';
import { gql } from '@apollo/client';

// var instance = axios.create({
//     baseURL: process.env.REACT_APP_GRAPHQL_URL
// });

// var bearerToken = process.env.REACT_APP_API_TOKEN

// var user = Auth.getAuth()

const SecurityGraphQL = {

    getSecurityDetails: (ticker) => {

        const GET_SECURITY_DETAILS = gql`
          query getSecurityDetails($ticker: String!)  {
            securities(where: {ticker: {_eq: $ticker}, deleted: {_eq: false}}) {
              id
              name
              description
              ticker
              hourlyChangeRate: hourly_change_rate
              dailyChangeRate: daily_change_rate
              nextEarningReportDate: next_earning_report_date
              type
              rating
              exchange
              tags: security_tags {
                tag
              }
            }
          }
        `;

        return GraphQLClient
        .query({
          query: GET_SECURITY_DETAILS,
          variables: {
            ticker: ticker
          },
          fetchPolicy: "no-cache"
        });
    },
    getAllSecuritiesByType: (type) => {

      const GET_ALL_STOCKS = gql`
        query getAllStocks($type: String!) {
          securities(where: {deleted: {_eq: false}, type: {_eq: $type}}, order_by: {rating: desc_nulls_last, name: asc}) {
            id
            name
            description
            ticker
            hourlyChangeRate: hourly_change_rate
            dailyChangeRate: daily_change_rate
            earningReportDate: next_earning_report_date
            type
            rating
            tags: security_tags {
              tag
            }
          }
        }
      `;

      return GraphQLClient
      .query({
        query: GET_ALL_STOCKS,
        variables: {
          type: type
        },
        fetchPolicy: "no-cache"
      });
    },
    getEarningReports: (date_from, date_to) => {

      const GET_EARNING_REPORTS = gql`
        query getEarningReports($dateFrom: timestamp!, $dateTo: timestamp!) {
          securities(where: {next_earning_report_date: {_gte: $dateFrom}, _and: {next_earning_report_date: {_lte: $dateTo}}}, order_by: {next_earning_report_date: asc}) {
            id
            name
            ticker
            description
            reportDate: next_earning_report_date,
            tags: security_tags {
              tag
            }
          }
        }
      `;


      return GraphQLClient
      .query({
        query: GET_EARNING_REPORTS,
        variables: {
          dateFrom: date_from,
          dateTo: date_to
        },
        fetchPolicy: "no-cache"
      });
    },
    getSecuritiesByRating: () => {

      const GET_BY_RATING = gql`
        query getAllStocks {
          securities(where: {deleted: {_eq: false}}, order_by: {rating: desc_nulls_last, name: asc}, limit: 20) {
            id
            name
            description
            ticker
            type
            rating
          }
        }
      `;

      return GraphQLClient
      .query({
        query: GET_BY_RATING,
        variables: {
        }
      });
    },
    searchByNames: (searchInputValue) => {

      const SEARCH = gql`
        query getSecurityDetails($searchInputValue: String!)  {
          securities(where: {_or: [{name: {_ilike: $searchInputValue}}, {ticker: {_ilike: $searchInputValue}}]}, order_by: {name: asc, ticker: asc}, limit: 20) {
            id
            ticker
            name
            description
            type
          }
        }
      `;

      return GraphQLClient
      .query({
        query: SEARCH,
        variables: {
          searchInputValue: "%"+searchInputValue+"%"
        }
      });
  },
   
}

export default SecurityGraphQL;