import './SignIn.css';
import React from 'react';
import { useState , useEffect} from "react";
import UserApi from '../api/UserApi';
import Auth from '../components/Auth';
import { setUser, setDisabledBuy } from '../store/UserReducer';
import { useDispatch } from 'react-redux'

function SignIn() {

    const dispatch = useDispatch()


    // let navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      email: process.env.REACT_APP_EMAIL,
      password: process.env.REACT_APP_PASSWORD
    });
  
    const [errorMsg, setErrorMsg] = useState("");
  
    useEffect(() => {
      Auth.signOut()
      // signUpWithEmailAndPassword()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const handleInputChange = (e) => {
      setUserInfo(userInfo => ({
        ...userInfo,
        [e.target.name]: e.target.value,
      }))
    }

    const signInWithEmailAndPassword = () => {
      console.log(userInfo)

      UserApi.authenticate(userInfo).then((response) => {
        console.log("response: ", response);

        Auth.signIn(response.data);

        dispatch(setUser(response.data))
        dispatch(setDisabledBuy(response.data.disabledBuy))

        window.location.href = "/";
        
      }).catch((error) => {
        console.error("Error msg: ", error.message);
        console.error("Error: ", error);
        if(error.response.data){
          setErrorMsg(error.response.data.message)
        }else{
          setErrorMsg(error.message+". Server may be down")
        }
        
      });

    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        signInWithEmailAndPassword()
      }
    }

  return (
    <>
      <div className="row">
        <div className="col-md-4 offset-md-4 mt-5">
          <form>
            <h1 className="h3 mb-3 fw-normal">Sign In</h1>
            <div className='row'>
              <div className="col-12 col-md-12">
                {
                  errorMsg && 
                  <div className="alert alert-danger">
                    {errorMsg}
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <div className="form-floating">
                  <input 
                  type="email" 
                  className="form-control"
                  autoComplete="email"
                  name="email"
                  value={userInfo.email}
                  onChange={handleInputChange}
                  required
                  placeholder="johndoe@gmail.com"
                  onKeyDown={(e)=>handleKeyDown(e)} 
                  />
                  <label>Email address</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <div className="form-floating">
                  <input 
                  type="password" 
                  className="form-control" 
                  value={userInfo.password}
                  onChange={handleInputChange}
                  required
                  name="password"
                  placeholder="Password"
                  onKeyDown={(e)=>handleKeyDown(e)} 
                  />
                  <label>Password</label>
                </div>
              </div>
            </div>
            <button onClick={()=>signInWithEmailAndPassword()} className="btn btn-primary w-100 py-2" type="button">Sign In</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignIn;
