import React from 'react';
import { useState , useEffect} from "react";
import {
  useNavigate
} from "react-router-dom";
import SecurityGraphQL from '../graphql/SecurityGraphQL';
import { TradeAssetType } from '../components/enum/OrderEnums';

function AllStocksPage() {

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [allStocks, setAllStocks] = useState([]);


  useEffect(() => {

    console.log("AllStocksPage")

    SecurityGraphQL.getAllSecuritiesByType(TradeAssetType.STOCK).then((response) => {
      console.log("stock details graphql response: ", response.data);  
      setAllStocks(response.data?.securities || [])
    }).catch((error) => {
        console.error("Error: ", error);
        setErrorMsg(error.message)
        console.error("Error: ", errorMsg);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col-12'>

          <div className='row mb-3'>
            <div className="col-12 col-sm-12">
              <button 
              onClick={()=>navigate("/security-create")}
              type="button"
              className="btn btn-outline-primary">Add</button>
            </div>
          </div>

          <div className='row mb-3'>
            <div className="col-12 col-sm-12">
              {
                allStocks && allStocks.length <= 0 &&
                <div className="alert alert-light">
                   No Stocks. Backend issue!
                </div>
              }
            </div>
          </div>
          
          <div className='row'>
            <div className="col-12 col-sm-12">
              

            <div className='row'>
            {
            
              allStocks.map((stock)=>(
                
                  <div className="col-12 col-sm-3 mb-3" key={stock.id}>


                    <div className='row'>
                      <div className="col-12 col-sm-12">
                      
                          <div className="card">
                            <div className="card-body">
                            
                              <div className='row'>
                                <div className="col-12 col-sm-6">
                                  <h5 className="card-title">{stock.name}</h5>
                                </div>
                                <div className="col-12 col-sm-6 text-end">
                                  <small><button className="btn btn-link" onClick={()=>navigate("/security-update?ticker="+stock.ticker)}>edit</button></small>
                                </div>
                              </div>
                              <h6 className="card-subtitle mb-2 text-body-secondary">
                                <button 
                                  onClick={()=>navigate("/stock?ticker="+stock.ticker)}
                                  type="button" 
                                  className="btn btn-link m-0 p-0">
                                ({stock.ticker})
                                </button>
                              </h6>
                              
                              <div className='row'>
                                <div className="col-12 col-sm-12">

                                  <div className='row'>
                                    <div className="col-12 col-sm-12">
                                      <b>Earning Report</b>
                                      <br/>
                                      {stock.earningReportDate}
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className="col-12 col-sm-12">
                                      <div className='row'>
                                        <div className="col-12 col-sm-12">
                                          <b>Tags</b>
                                        </div>
                                      </div>
                                      <div className='row mt-2'>
                                        <div className="col-12 col-sm-12">
                                          {
                                            stock.tags.map((tagObj)=>(
                                              <span className='bg-body-secondary border rounded-pill p-2 me-1' key={tagObj.tag}>{tagObj.tag}</span>
                                            ))
                                          }
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                              

                                </div>
                              </div>



                            </div>
                          </div>

                        
                      </div>
                    </div>
                 
                  </div>
              ))
            }
             </div>


            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-sm-12">
              <div className='row'>
                <div className="col-12 col-sm-12">
                  
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
    </>
  );
}

export default AllStocksPage;
