
import React from 'react';
import { useEffect, useState, useRef} from "react";
import SecurityGraphQL from '../graphql/SecurityGraphQL'
import {
    useNavigate
  } from "react-router-dom";
import SecurityApi from '../api/SecurityApi';

function TickerSearch() {


    const [errorMsg, setErrorMsg] = useState("");

    const navigate = useNavigate();

    const blurTimeoutRef = useRef(); // ref to keep track of the timeout

    const [inputValue, setInputValue] = useState('');
    const [focus, setFocus] = useState(false);
    const [tickers, setTickers] = useState([]);

    useEffect(() => {

        // Clear the timeout on unmount
        return () => clearTimeout(blurTimeoutRef.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadTickers = () => {
        SecurityGraphQL.getSecuritiesByRating().then((response) => {
            console.log("High rated securities: ", response.data);  

            let securities = response?.data?.securities

            if(securities){
                setTickers(securities)
            }

        }).catch((error) => {
            console.error("Error: ", error);
            setErrorMsg(error.message)
            console.error("Error: ", errorMsg);
        });
    }

    const handleFocus = () => {
        console.log("focus")
        loadTickers()

        clearTimeout(blurTimeoutRef.current); // Clear any existing timeouts
        setFocus(true);
    };
    
    const handleBlur = () => {
        console.log("out of focus")
        // setFocus(false);
        blurTimeoutRef.current = setTimeout(() => {
            setFocus(false)
            setInputValue('')
        }, 200);
    };

    const search = (event) => {
        let value = event.target.value
        setInputValue(value);

        SecurityGraphQL.searchByNames(event.target.value).then((response) => {
            console.log("stock details graphql response: ", response.data);  

            let securities = response?.data?.securities || []

            securities.unshift(
                {
                    ticker: value,
                    name: "ADD_NEW"
                }
            )

            setTickers(securities)

        }).catch((error) => {
            console.error("Error: ", error);
            setErrorMsg(error.message)
            console.error("Error: ", errorMsg);
        });
    };

    const goToSecurityPage = (security) => {
        console.log("security: ", security);  
        setFocus(false)
        setInputValue('')

        if(security.name==='ADD_NEW'){
            SecurityApi.createBySymbol(security.ticker).then((response) => {
                console.log("create security by symbol response: ", response.data);

                let security = response.data
                
                // navigate("/stock?ticker="+security.ticker)

                window.location.href = "/stock?ticker="+security.ticker;

            }).catch((error) => {
                

                console.error("Error msg: ", error.message);
                console.error("Error: ", error);

                if(error.response.data){
                    setErrorMsg(error.response.data.message)
                }else{
                    setErrorMsg(error.message+". Server may be down")
                }

                setTimeout(() => {
                    setErrorMsg("")
                }, 3000);

            });
        }else{
            navigate("/stock?ticker="+security.ticker)
        }

        


        // if(ticker.type==="STOCK"){
        //     navigate("/stock?ticker="+ticker.ticker)
        // }else{
        //     navigate("/crypto?ticker="+ticker.ticker)
        // }


        // if(ticker.type==="STOCK"){
        //     window.location.href = "/stock?ticker="+ticker.ticker
        // }else{
        //     window.location.href = "/crypto?ticker="+ticker.ticker
        // }

        
    };

    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12">
                    <input 
                    value={inputValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={search}
                    type="text" 
                    className="form-control" 
                    placeholder='ticker'/>

                    {
                        errorMsg && 
                        <div className="alert alert-danger">
                            {errorMsg}
                        </div>
                    }
                </div>
            </div>
            <div className='row'>
                <div className="col-12 col-sm-12">
                    {
                        focus && 
                        <ul className="list-group p-0 m-0" style={{
                            position: 'absolute',
                            maxHeight: '250px',
                            overflowY: 'auto',
                            zIndex: 1000 // Add this line
                        }}>
                            {
                                tickers.length>0 &&
                                tickers.map((security, index)=>(
                                    <li 
                                    key={index}
                                    onClick={()=>goToSecurityPage(security)}
                                   
                                    className="list-group-item">
                                        {security.ticker}(<i>{security.name}</i>)
                                        
                                        {/* <button type="button"
                                        
                                        // onClick={()=>goToSecurityPage(ticker)}
                                        className="btn btn-outline-primary">{ticker.ticker}(<i>{ticker.name}</i>)</button>     */}
                                    </li>
                                    
                                ))
                            }
                        </ul>
                    }
                    
                </div>
            </div>
        </>
    );
}

export default TickerSearch;
