
import React from 'react';
import { useState , useEffect} from "react";
import WebsocketConnector from '../components/WebsocketConnector';
import ActiveTrade from '../components/ActiveTrade';
import TradeHistoryMinDetail from '../components/TradeHistoryMinDetail';
import { TradeAssetType } from '../components/enum/OrderEnums';
import ActiveOptionTrade from '../components/ActiveOptionTrade';
import TradeGraphQL from '../graphql/TradeGraphQL';

function Trades() {

  const stompClient = WebsocketConnector.connect()

  const [allActiveTrades, setAllActiveTrades] = useState([]);

  const [historicalTrades, setHistoricalTrades] = useState({content:[], first: false, last: false, pageNumber: 0, pageSize: 25, totalPages: 0});

  useEffect(() => {

    TradeGraphQL.subscribeToActiveTrades()
    .subscribe({
      next(result) {
        console.log(`client sub resultss`)
        console.log(result)

        setAllActiveTrades(result?.data?.trades)

      },
      error(err) { console.error('err', err); },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNonActiveTrades = () => {

    TradeGraphQL.getHistoricalTrades().then((response) => {
      console.log("historical trades data: ", response);  
      setHistoricalTrades(response?.data?.trades)
    }).catch((error) => {
        console.error("Error: ", error);
    });
  }

  useEffect(() => {

    fetchNonActiveTrades()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    stompClient.onConnect = (frame) => {
      console.log('option Connected:', frame);

      stompClient.subscribe('/topic/all-current-trades', (message) => {
        let trades = JSON.parse(message.body)
        console.log("got active trades data", trades)
        setAllActiveTrades(trades)
      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='row'>
        <div className="col-12 col-md-12">

          <div className='row'>
            <div className="col-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <b>Active Trades</b>
                </div>
                <div className="card-body">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                    { 
                      allActiveTrades.length>0 &&
                      allActiveTrades.map(trade => (
                        <div className='row' key={trade.id}>
                          <div className="col-12">

                            {
                              trade.assetType === TradeAssetType.STOCK &&
                              <ActiveTrade trade={trade} reloadHistory={fetchNonActiveTrades}/>
                            }

                            {
                              trade.assetType === TradeAssetType.OPTIONS &&
                              <ActiveOptionTrade trade={trade} reloadHistory={fetchNonActiveTrades}/>
                            }

                            
                          </div>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr/>

          <div className='row'>
            <div className="col-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <b>History Trades</b>
                </div>
                <div className="card-body">
                  <div className='row'>
                    <div className="col-12 col-md-12">
                    { 
                      historicalTrades && historicalTrades.length>0 &&
                      historicalTrades.map(trade => (
                        <div className='row' key={trade.id}>
                          <div className="col-12">
                            <TradeHistoryMinDetail trade={trade} title='' />
                          </div>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Trades;
