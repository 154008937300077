import React from 'react';
import { useEffect, useState } from "react";
import ZoomImageModal from "../../components/ZoomImageModal";
import YouTube from 'react-youtube';
import Accordion from 'react-bootstrap/Accordion';

const images = [
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-1.png",
    alt: "head-shoulder-1",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-2.png",
    alt: "head-shoulder-2",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-3.png",
    alt: "head-shoulder-3",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-4.jpeg",
    alt: "head-shoulder-4",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-5.png",
    alt: "head-shoulder-5",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-6.png",
    alt: "head-shoulder-6",
    explanation:""
  },
  {
    url: "https://folaulau.github.io/my-trader-assets/chart-patterns/head_and_shoulder/head-and-shoulders-7.png",
    alt: "head-shoulder-7",
    explanation:""
  },
]

const videos = [
  {
    youTubeId: "QBlGGL5cyt8",
    title: "",
    notes: "asdfasd"
  },
  {
    youTubeId: "T5uZqbbJIdk",
    title: "",
    notes: ""
  },
  {
    youTubeId: "6pLJwQ1oYQs",
    title: "",
    notes: ""
  },
  {
    youTubeId: "CczCRJyma4Q",
    title: "",
    notes: ""
  }
]

const resources = [
  {
    title: "Investopedia",
    url: "https://www.investopedia.com/terms/i/inverseheadandshoulders.asp"
  },
  {
    title: "Daily Price Action",
    url: "https://dailypriceaction.com/forex-beginners/inverse-head-shoulders-pattern/"
  },
  {
    title: "Tradingview",
    url: "https://www.tradingview.com/ideas/headandshoulders/"
  },
  {
    title: "School Stockcharts",
    url: "https://school.stockcharts.com/doku.php?id=chart_analysis:chart_patterns:head_and_shoulders_bottom_reversal"
  },
  {
    title: "Options Tradingiq",
    url: "https://optionstradingiq.com/inverse-head-and-shoulders/"
  },
]

function HeadAndShouldersChartPatternPage() {

  console.log("HeadAndShouldersChartPatternPage")

  const [zoomImage, setZoomImage] = useState({show: false, imageUrl:"",imageAlt:""})

  const youtubeOpts = {
    height: '370',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomInImage = (image) => {
    console.log("zoomInImage, ", image)
    setZoomImage({show: true, imageUrl: image.url, imageAlt: image.alt})
  }

  const videoOnReady = (event) => {
    // The video is ready; you can access the player object via event.target
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h4>Header and Shoulders Chart Pattern</h4>
            </div>
          </div>


          <div className='row'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
              <h5>Descriptions</h5>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              The head and shoulders chart pattern is a reliable indicator used in technical analysis to predict a trend reversal from bullish to bearish. It's a formation you would typically look for after there has been a consistent upward trend in the price of a stock, cryptocurrency, or forex market.
              The pattern itself is composed of three peaks: a higher peak (the head) between two lower ones (the shoulders). The "neckline" is the line that connects the low points of the two valleys that are formed on either side of the head. The rule of thumb is that the final peak should not exceed the height of the head, which is the central and highest peak.
              </div>
            </div>



   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

              <div className='row'>
                <div className='col-12'>
                  <h5>Images</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <small>(click on image to zoom in)</small>
                </div>
              </div>

              <div className='row'>
                {
                  images.map((image) => (
                    <div className='col-12 col-sm-3 mb-2 cursor-selection' key={image.alt} onClick={()=>zoomInImage(image)}>
                      <img src={image.url} className="img-fluid" alt={image.alt}></img>
                      <div></div>
                      {image.explanation}
                    </div>
                    
                  ))
                }
              </div>    
              <div className='row'>
                <div className='col-12'>
                  <ZoomImageModal 
                    showModal={zoomImage.show} 
                    imageUrl={zoomImage.imageUrl} 
                    imageAlt={zoomImage.imageAlt} 
                    closeModal={()=>(
                      setZoomImage({show: false})
                    )}
                    />
                </div>
              </div>   
   
            </div>
          </div>

          <div className='row mt-4 border p-2'>
            <div className='col-12'>

            <div className='row'>
              <div className='col-12'>
                <h5>Videos</h5>
              </div>
            </div>

            <div className='row'>
                {
                  videos.map((video) => (
                    <div className='col-12 col-sm-6 text-center mb-4' key={video.youTubeId}>
                      
                      <b>{video.title}</b>

                      <YouTube videoId={video.youTubeId} opts={youtubeOpts} onReady={videoOnReady} />

                      <div className='row'>
                        <div className='col-12 col-sm-10 offset-sm-1'>
                          <Accordion>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>Notes</Accordion.Header>
                              <Accordion.Body>
                                {video.notes}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>

                    </div>
                    
                  ))
                }
              </div>    

            <div className='row'>
              <div className='col-12'>

              </div>
            </div>
    
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                      {
                        resources.map((resource)=>(
                          <div className='row' key={resource.url}>
                            <div className='col-12'>
                              <a href={resource.url}
                                rel="noreferrer"
                                target="_blank"
                                className="link-underline-primary">
                                  {resource.title}</a>
                            </div>
                          </div>
                        ))
                      }
                  </div>
                </div>

              </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default HeadAndShouldersChartPatternPage;
