import { Outlet } from 'react-router-dom';


function StrategyOutlet() {

  return (
    <>
      <Outlet/> 
    </>
  );
}

export default StrategyOutlet;
