import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect} from "react";
import { TradeAssetType } from './enum/OrderEnums';

function TradeHistoryMinDetail({ trade, title }) {

  const navigate = useNavigate();

  var profit = trade.profit
  var profitBackground = ''

  profit = (profit) ? profit.toFixed(2) : 0.0

  if(profit<0){
    profitBackground = 'bg-danger-subtle'
    profit = '-$'+(profit * -1)
  }else{
    profitBackground = 'bg-success-subtle'
    profit = '$'+profit
  }


  var tsProfit = trade.tradeStationProfit
  var tsProfitBackground = ''

  // console.log("tsProfit, ", tsProfit, trade.id)

  tsProfit = (tsProfit!==null) ? tsProfit.toFixed(2) : 0

  if(tsProfit<0.0){
    tsProfitBackground = 'bg-danger-subtle'
    tsProfit = '-$'+(tsProfit * -1)
  }else{
    tsProfitBackground = 'bg-success-subtle'
    tsProfit = '$'+tsProfit
  }

  // console.log("calculated tsProfit, ", tsProfit, trade.id)

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewHistory = () => {
    console.log("view history")
    navigate('/trade-history?uuid='+trade.uuid);
  }

  return (
    <>
      <div className='row border m-2 p-2'>
        <div className="col-12 col-md-12">
          <div className='row'>
            <div className="col-12 col-md-12">
              <b>{title}</b>
            </div>
          </div>
          <div className='row'>
            <div className="col-12 col-md-12">
              <div className='row'>
                <div className="col-12">
                  <div className='row'>
                    <div className="col-6 col-sm-3">
                      
                      <div className='row'>
                        <div className="col-12 col-sm-4">

                          <button 
                            onClick={()=>navigate('/stock?ticker='+trade.ticker)}
                            type="button" 
                            className="btn btn-link">
                              {trade.ticker}
                          </button>
                          
                        </div>
                        <div className="col-12 col-sm-4">
                          <b>Id</b>
                          <br/>
                          {trade.id}
                        </div>
                        <div className="col-12 col-sm-4">
                          {
                            (trade.assetType===TradeAssetType.STOCK || trade.assetType===TradeAssetType.CRYPTO) &&
                            <div>
                              <b>Shares</b>
                              <br/>
                              {trade.numberOfShares}
                            </div>
                          }
                          {
                            trade.assetType===TradeAssetType.OPTIONS &&
                            <div>
                              <b>Contracts</b>
                              <br/>
                              {trade.numberOfContracts}
                            </div>
                          }
                         
                        </div>
                      </div>

                    </div>
                    <div className={'col-6 col-sm-2 '+profitBackground+' text-center'}>
                      <b>System Profit</b>
                      <br/>
                      {profit}
                    </div>
                    <div className={'col-6 col-sm-2 '+tsProfitBackground+' text-center'}>
                      <b>T/S Profit</b>
                      <br/>
                      {tsProfit}

                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Sold Total Price</b>
                      <br/>
                      ${trade.soldTotalPrice}
                    </div>
                    <div className="col-6 col-sm-2">
                      <b>Purchase Total Price</b>
                      <br/>
                      ${trade.purchasedTotalPrice}
                    </div>
                  </div>
                  {
                    (trade.assetType===TradeAssetType.STOCK || trade.assetType===TradeAssetType.CRYPTO) &&
                    <div className='row mt-2'>
                      <div className="col-6 col-sm-3">
                        <b>Share Purchased Price</b>
                        <br/>
                        ${trade.sharePurchasedPrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Share Sold Price</b>
                        <br/>
                        ${trade.shareSoldPrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Stop Loss Limit Price</b>
                        <br/>
                        ${trade.stopLossLimitPrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Stop Loss Price</b>
                        <br/>
                        ${trade.stopLossPrice}
                      </div>
                    </div>
                  }
                  {
                    trade.assetType===TradeAssetType.OPTIONS &&
                    <div className='row mt-2'>
                      <div className="col-6 col-sm-3">
                        <b>Option Sold Price</b>
                        <br/>
                        ${trade.optionSoldPrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Option Purchased Price</b>
                        <br/>
                        ${trade.optionPurchasedPrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Option Strike Price</b>
                        <br/>
                        ${trade.optionStrikePrice}
                      </div>
                      <div className="col-6 col-sm-3">
                        <b>Stop Loss Price</b>
                        <br/>
                        ${trade.stopLossPrice}
                      </div>
                    </div>
                  }
                  <div className='row mt-2'>
            
                    <div className="col-6 col-sm-3">
                      <b>Direction</b>
                      <br/>
                      {trade.direction}
                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Order Type</b>
                      <br/>
                      {trade.orderType}
                    </div>
                    
                    <div className="col-6 col-sm-3">
                      <b>Asset Type</b>
                      <br/>
                      {trade.assetType}
                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Updated At</b>
                      <br/>
                      {trade.updatedAt}
                    </div>

                  </div>

                  <div className='row mt-2'>
                    <div className="col-6 col-sm-3">
                      <b>Sold At</b>
                      <br/>
                      {trade.soldAt}
                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Sold Type</b>
                      <br/>
                      {trade.soldType}
                    </div>
                    <div className="col-6 col-sm-3">
                      
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            className={`fa fa-star ${star <= trade.rating ? "text-warning" : "text-muted"}`}
                          ></span>
                        ))}
                    </div>
                    <div className="col-6 col-sm-3">
                      <button 
                        onClick={()=>viewHistory()}
                        type="button" 
                        className="btn btn-outline-primary btn-sm">
                          view history
                        </button>
                    </div>
                  </div>

                  <div className='row'>
                    
                    <div className="col-6 col-sm-3">
                      <b>Buy Limit Price</b>
                      <br/>
                      ${trade.buyLimitPrice}

                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Sell Limit Price</b>
                      <br/>
                      ${trade.sellLimitPrice}

                    </div>
                    <div className="col-6 col-sm-3">
                      <b>Status</b>
                      <br/>
                      {trade.status}
                    </div>
                  </div>

                  <div className='row mt-2'>
                    <div className="col-12 col-sm-12">
                    {
                      trade.tsSellLimitOrder && trade.tsSellLimitOrder.data &&
                      <div className='row'>
                        <div className="col-12 col-sm-12">

                          <div className='row'>
                            <div className="col-12 col-sm-3">
                              <b>Order Type</b>
                              <br/>
                              Sell - {trade.tsSellLimitOrder.data.orderType}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Limit Price</b>
                              <br/>
                              ${trade.tsSellLimitOrder.data.limitPrice}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Status</b>
                              <br/>
                              {trade.tsSellLimitOrder.data.status},  {trade.tsSellLimitOrder.data.statusDescription}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Duration</b>
                              <br/>
                              {trade.tsSellLimitOrder.data.duration},   {trade.tsSellLimitOrder.data.goodTillDate}
                            </div>
                        
                          </div>
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className="col-12 col-sm-12">
                    {
                      trade.tsStopLossOrder && trade.tsStopLossOrder.data &&
                      <div className='row'>
                        <div className="col-12 col-sm-12">

                          <div className='row'>
                            <div className="col-12 col-sm-3">
                              <b>Order Type</b>
                              <br/>
                              Sell - {trade.tsStopLossOrder.data.orderType}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Stop Loss Price</b>
                              <br/>
                              ${trade.tsStopLossOrder.data.stopPrice}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Status</b>
                              <br/>
                              {trade.tsStopLossOrder.data.status},  {trade.tsStopLossOrder.data.statusDescription}
                            </div>
                            <div className="col-12 col-sm-3">
                              <b>Duration</b>
                              <br/>
                              {trade.tsStopLossOrder.data.duration},   {trade.tsStopLossOrder.data.goodTillDate}
                            </div>
                    
                          </div>
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TradeHistoryMinDetail;
