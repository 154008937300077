

const resources = [
  {
    title: "Trend Spider",
    url: "https://trendspider.com/blog/how-to-trade-earnings-announcements-with-technical-analysis-trendspider-blog/"
  },
  {
    title: "Quant Strategies",
    url: "https://quantstrategies.academy/2020/05/19/the-21-most-popular-trading-strategies-every-serious-trader-should-learn-to-succeed/"
  },
  {
    title: "Investors Underground Youtube",
    url: "https://www.youtube.com/@investorsunderground"
  }
]


function StrategyPage() {

  console.log("StrategyPage")

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4>Strategies</h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            Chart patterns are important tools in technical analysis for understanding market sentiment and predicting future price movements. 
            They give traders insights into market psychology, helping them make educated decisions on entry and exit points. 
            Recognizing these patterns can increase the probability of successful trades.
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>How to Use Them</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Identification</b>: The first step is to identify a pattern forming in the chart.
                    </li>
                    <li>
                      <b>Confirmation</b>: Wait for the pattern to complete. In some cases, volume analysis or other indicators can confirm the pattern.
                    </li>
                    <li>
                      <b>Entry/Exit Strategy</b>: Based on the pattern, determine your entry and exit points.
                    </li>
                    <li>
                      <b>Risk Management</b>: Always consider stop-loss and take-profit levels to manage risk.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>When to Use Them</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <ol>
                    <li>
                      <b>Trend Reversal</b>: To identify when an existing trend is about to change direction.
                    </li>
                    <li>
                      <b>Trend Continuation</b>: To confirm that an existing trend is likely to continue.
                    </li>
                    <li>
                      <b>Breakout</b>: When the price moves outside a defined support or resistance level.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <h5>Top 5 Trading Chart Patterns</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <b>1. Double Top and Double Bottom</b>
                  <ul>
                    <li>
                      <b>Type</b>: Reversal
                    </li>
                    <li>
                      <b>How to Use</b>: Indicates a trend is about to reverse. 
                      A buy or sell signal is generated when the price breaks the "neckline" created by the two peaks (Double Top) or troughs (Double Bottom).
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <b>2. Head and Shoulders</b>
                  <ul>
                    <li>
                      <b>Type</b>: Reversal
                    </li>
                    <li>
                      <b>How to Use</b>: Used to predict a reversal in the trend. The pattern is confirmed when the price moves beyond the neckline.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <b>3. Cup and Handle</b>
                  <ul>
                    <li>
                      <b>Type</b>: Continuation
                    </li>
                    <li>
                      <b>How to Use</b>: How to Use: Often a bullish continuation pattern. The entry point is generally a break above the "handle."
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <b>4. Flags and Pennants</b>
                  <ul>
                    <li>
                      <b>Type</b>: Continuation
                    </li>
                    <li>
                      <b>How to Use</b>: Flags and Pennants often indicate a brief consolidation before the previous move resumes. 
                      The breakout/breakdown point serves as an entry or exit.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <b>5. Triangles (Ascending, Descending, and Symmetrical)</b>
                  <ul>
                    <li>
                      <b>Type</b>: Continuation or Reversal
                    </li>
                    <li>
                      <b>How to Use</b>: Triangles are used to identify the continuation or reversal of a trend. 
                      The entry point is the breakout/breakdown from the triangle.
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
            Chart patterns are important tools in technical analysis for understanding market sentiment and predicting future price movements. 
            They give traders insights into market psychology, helping them make educated decisions on entry and exit points. 
            Recognizing these patterns can increase the probability of successful trades.
            </div>
          </div>

          <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <h5>Resources</h5>

                <div className='row'>
                  <div className='col-12'>
                    
                    {
                      resources.map((resource)=>(
                        <div className='row' key={resource.url}>
                          <div className='col-12'>
                            <a href={resource.url}
                              rel="noreferrer"
                              target="_blank"
                              className="link-underline-primary">
                                {resource.title}</a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>

              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StrategyPage;
