import './Header.css';
import React from 'react';
import { useEffect} from "react";
import Auth from '../components/Auth';
import AccountDetails from '../components/AccountDetails';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  useNavigate
} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import WebsocketConnector from '../components/WebsocketConnector';
import { useDispatch } from 'react-redux'
import { addPosition } from '../store/PositionsReducer';
import NoteApi from '../api/NoteApi';

function Header() {

  const navigate = useNavigate();

  const dispatch = useDispatch()

  // console.log('tradeStationPositions, ', tradeStationPositions)

  const stompClient = WebsocketConnector.connect()

  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const firstSegment = pathSegments[0] || '';

  // console.log('firstSegment, ', firstSegment)

  const tabs = [
        {
      name: 'Today\'s Note',
      urlSlug : 'note',
      sidebar: 'note',
      highlight: firstSegment==="note"
    },
    {
      name: 'Indicators',
      urlSlug : 'indicators',
      sidebar: 'indicator',
      highlight: firstSegment==="indicators"
    },
    {
      name: 'Strategies',
      urlSlug : 'strategies',
      sidebar: 'strategy',
      highlight: firstSegment==="strategies"
    },
    {
      name: 'Chart Patterns',
      urlSlug : 'chart-patterns',
      sidebar: 'chart-pattern',
      highlight: firstSegment==="chart-patterns"
    },
  ]

  useEffect(() => {
    if(!Auth.isAuthenticated()){
      window.location.href = '/signin'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    stompClient.onConnect = (frame) => {
      console.log('current tradestation positions connected:');

      stompClient.subscribe('/topic/tradestation/positions', (message) => {
        let position = JSON.parse(message.body)
        // console.log("tradestation position ", position)

        dispatch(addPosition(position));
      });
    };

    stompClient.activate();  // Activate the connection

    // This return function will be called when the component unmounts
    return () => {
      if (stompClient.connected) {
        stompClient.deactivate();  // Deactivate the connection
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const signOut = () => {
    console.log("signing out")
    Auth.signOut()
    window.location.href = '/signin';
  }

  const goTo = (urlSlug, sidebar) => {
    if(urlSlug==='note'){

      NoteApi.createOrGet('today').then((response) => {
        console.log("createOrGet note response: ", response.data);

        let note = response?.data
  
        let url = "/journal/view"
        
        if(note.uuid){
          url += "?uuid="+note.uuid
        }
  
        navigate(url)
  
      }).catch((error) => {
        console.error("Error: ", error);
      });

    }else{
      // navigate("/"+urlSlug+"?sidebar="+sidebar)
      window.location.href = "/"+urlSlug+"?sidebar="+sidebar;
    }
    
  }

  return (
    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between py-3 mb-3 border-bottom">
      <div className="col-sm-2">
        <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none">
          My Trader
        </a>
      </div>

      <div className="nav col-sm-6 col-12 col-sm-auto justify-content-center">
        <AccountDetails />
      </div>

      <div className="col-sm-4 text-end">

            <div className='row'>
              <div className='col-12 d-grid gap-2 d-sm-flex justify-content-sm-end'>

                {
                  tabs.map((tab)=>(
                    <button key={tab.name}
                      onClick={()=>goTo(tab.urlSlug, tab.sidebar)}
                      type='button'
                      className={`btn btn-outline-primary btn-sm ${tab.highlight ? 'active' : ''}`}
                      >
                        {tab.name}
                      </button>
                  ))
                }
                  
                <DropdownButton
                  variant="outline-primary"
                  title="Account"

                >
                  <Dropdown.Item onClick={()=>navigate("/my-account")}>My Account</Dropdown.Item>
                  <Dropdown.Item onClick={()=>navigate("/all-stocks")}>Stocks</Dropdown.Item>
                  <Dropdown.Item onClick={()=>navigate("/all-cryptos")}>Cryptos</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={()=>signOut()}>Sign Out</Dropdown.Item>
                </DropdownButton>

              </div>
            </div>

      </div>
    </header>
  );
}

export default Header;
