import { configureStore } from '@reduxjs/toolkit'
import ActiveTradesReducer from './ActiveTradesReducer'
import UserReducer from './UserReducer'
import PositionsReducer from './PositionsReducer'

export default configureStore({
  reducer: {
    userInfo: UserReducer,
    activeTrades: ActiveTradesReducer,
    tradeStationPositions: PositionsReducer
  }
})