import './Navbar.css';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import MainSidebar from './sidebars/MainSidebar';
import ChartPatternSidebar from './sidebars/ChartPatternSidebar';
import StrategySidebar from './sidebars/StrategySidebar';
import IndicatorSidebar from './sidebars/IndicatorSidebar';

function Navbar() {

  const [searchParams,] = useSearchParams();

  // Get a specific query parameter
  const sidebar = searchParams.get('sidebar');

  if(sidebar==='chart-pattern'){
    return <ChartPatternSidebar />
  }

  if(sidebar==='strategy'){
    return <StrategySidebar />
  }

  if(sidebar==='indicator'){
    return <IndicatorSidebar />
  }

  if(sidebar==='signin'){
    return <></>
  }

  return <MainSidebar />
}

export default Navbar;
